import { Button, Popover, Spin, Table } from 'antd'
import { DocumentRef } from '../../graphql/API';
import { useGlobalState } from "../../utils/globlStateProvider";
import {DocumentsType, BillCategories} from './Types'
import { useState} from 'react';
import { FileSearchOutlined,DownloadOutlined } from '@ant-design/icons'
import { getDocumentsPreview, getInstallationIdsByDocument, humanFileSize, isBill, onlyUnique, openDocument } from '../../utils/helpers';
import useWindowDimensions from './useWindowDimensions';
import moment from 'moment';
import React from 'react';

function AhTable(props:{documents:DocumentsType | undefined,contractsAreLoaded?:boolean, isSingleInstalation?:boolean}){
    const { state } = useGlobalState()
    const [fileUrls, setFileUrls] = useState<any[]>([])
    const [filteredInfo, setFilteredInfo] = React.useState({installationNumber:'',type:''})
    const [sortedInfo, setSortedInfo] = React.useState({columnKey:'date',order:'descend'})
    // eslint-disable-next-line 
    const { height, width } = useWindowDimensions();// eslint-disable-this-line no-unused-vars

    const dataSource:any = []

    const getType = (document:DocumentRef) => {
       return isBill(document.form) && document.form?.categories.map( function (categoryItem,index) {
            var labels: any[] = []
            BillCategories.forEach( contractCategory => {
                if(contractCategory.value === categoryItem){
                    labels.push(<div key={document.s3Key + index}>{contractCategory.label}</div>)
                }
            })

            if(categoryItem === 'signed-contract'){
                labels.push(<div key={document.s3Key + index}>Aufzughelden Vertrag</div>)
            }
            return labels;
        })
    }

    let installationNumbers:string[] = []
    let type:string[] = []
    
    props.documents?.bills?.forEach(itemDocument => {
        if(itemDocument?.form && isBill(itemDocument.form)){

            // fill installationNumbers selector
            if(installationNumbers.indexOf(getInstallationIdsByDocument(itemDocument,state.contracts,false).toString()) < 0){
                let arrInstallationNumbers = getInstallationIdsByDocument(itemDocument,state.contracts,false)
                arrInstallationNumbers.forEach(item => {
                    installationNumbers.push(item)
                })
              }

              // fill type selector
              if(isBill(itemDocument.form)){
                  itemDocument?.form.categories.forEach((categoryItem:string)=>{
                      if(type.indexOf(categoryItem) < 0){
                          BillCategories.forEach( billCategory => {
                              if(billCategory.value === categoryItem){
                                  type.push(billCategory.label)
                              }
                          })
                      }
                  })
              }

            let documentAllreadyLoaded = false
            fileUrls.forEach( (loadedItem) => {
                if(loadedItem.s3Key === itemDocument.s3Key){
                    documentAllreadyLoaded = true
                }
            })
            if(!documentAllreadyLoaded){
                setFileUrls((fileUrls: any) => [...fileUrls,itemDocument])
            }

            dataSource.push({
            key: itemDocument.s3Key,
            installationNumber: getInstallationIdsByDocument(itemDocument,state.contracts),
            type: getType(itemDocument),
            date: itemDocument?.form?.billingDate? moment(itemDocument?.form?.billingDate).format('L'): '-',
            originalFileName: itemDocument.originalFileName,
            mimetype: itemDocument.mimeType,
            size: humanFileSize(itemDocument.size,true,0),
            filename: itemDocument.originalFileName,
            url: itemDocument.s3Key,
            })
        }
    })

    const responsiveBreakpoint = 800

    let fixed = {}
    let nameWidth = {}
    let labelName = 'AUFZUGNUMMER'
  
    if(width < responsiveBreakpoint){
      fixed = {fixed: 'left'}
      nameWidth = {width: 150}
      labelName = 'AUFZUG'
    }

    let installationNumberFilterOptions:any[] = []
    installationNumbers.sort().filter(onlyUnique).forEach(item => {
        installationNumberFilterOptions.push({text:item,value:item})
    })

    let typeFilterOptions:any[] = []
    type.sort().filter(onlyUnique).forEach(item => {
        typeFilterOptions.push({text:item,value:item})
    })

    let columns:any = [
    {
        title: labelName,
        dataIndex: 'installationNumber',
        key: 'installationNumber',
        ellipsis: true,
        filters: installationNumberFilterOptions,
        filteredValue: filteredInfo?.installationNumber || null,  
        onFilter: (value: any, record: { installationNumber: Array<any>; }) => {
            let foundvalue = false
            record.installationNumber.forEach( elem => {
                if(elem.props.children.indexOf(value) === 0){
                    foundvalue = true
                }
            })
            return foundvalue 
        },
          ...fixed,
          ...nameWidth
    },
    {
        title: 'Typ',
        dataIndex: 'type',
        key: 'type',
        filters: typeFilterOptions,
        filteredValue: filteredInfo?.type || null,
        onFilter: (value: any, record: { type: Array<any>; }) => {
            let foundvalue = false
            record.type.forEach( elem => {
                // had an empty array thats why elem[0]?
                if(elem[0]?.props?.children === value){
                    foundvalue = true
                }
            })
            return foundvalue 
        },
        
    },
    {
        title: 'Datum',
        dataIndex: 'date',
        key: 'date',
        sorter:  (a: { date: string ; }, b: { date: string ; }) => a.date.localeCompare(b.date),
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
    },
    {
        title: 'Dateiname',
        dataIndex: 'filename',
        key: 'filename',
    },
    {
        title: 'Aktionen',
        dataIndex: 'url',
        key: 'url',
        render: (t: any, r: any ) => {
            return <>
                    <Popover 
                        placement="left"
                        content={ () => {
                            let content = <Spin />
                            fileUrls.forEach( (loadedDocument) => {
                                    if(loadedDocument.s3Key === r.key){
                                        content = <>{getDocumentsPreview( loadedDocument )}</>
                                    }
                                })
                            return content
                            }
                        }
                        trigger="hover"
                        className="pl-2"
                        >
                        <FileSearchOutlined onClick={()=>openDocument(r,false)} className="pr-4" style={{ fontSize: '20px', color: '#08c' }} />
                    </Popover>
                    <DownloadOutlined onClick={()=>openDocument(r,true)} className="pr-4" style={{ fontSize: '20px', color: '#08c' }} />
                    {r.size}
                </>
        }
    }
    ];
    
    if(props.isSingleInstalation){
        columns = columns.filter((col: { dataIndex: string; }) => col.dataIndex !== 'installationNumber');
    }

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters)
        setSortedInfo(sorter)
    };

    const clearAll = () => {
        setFilteredInfo({installationNumber:'',type:''})
      };
    
    const isFilterButtonDisabled=()=>{
        if(filteredInfo.installationNumber?.length > 0 || filteredInfo.type?.length > 0){
            return false
        }
        else{
            return true
        }
    }

    return <>
        <div className={ props.isSingleInstalation?'innerElement pl-8 pr-8':'innerElement' }><Button disabled={isFilterButtonDisabled()} onClick={()=>clearAll()}>Filter zurücksetzen</Button></div>
        <br/>
        <Table 
        rowClassName={(record, index) => index % 2 === 0 ? '' :  'bg-greyLighter'}
        dataSource={dataSource} 
        columns={columns} 
        onChange={handleChange} 
        loading={!state.contractsAreLoaded}
        onRow={(record, rowIndex) => {
            return {
                onClick: event => {}, // click row
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {}, // mouse leave row
            };
            }}
            scroll={{ x: responsiveBreakpoint }}
            sticky
            />
        </>
}


export default AhTable;
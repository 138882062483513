import './Button.css'
import ArrowButton from './../../assets/images/arrow_button.svg'
import { Link } from 'react-router-dom';

export enum stylesButton {
    darkBlue = 'bg-blueDark',
    lightBlue = 'bg-blueLight',
    warnRed = 'bg-warnRed'
  }

function AhButton(props:{label:string,styles?:stylesButton, onClick?: () => void , linkToExtern?:string|undefined }) {

    let arrClasses = ['ahButton']

    if(props.styles){
        arrClasses.push(props.styles)
    }

    if(props.linkToExtern !== undefined){
      return (
        <Link to={ {pathname:props.linkToExtern}} target="_blank">
          <div onClick={props.onClick} className={arrClasses.join(' ')}>
              <span>{props.label}</span>
              <img className="" alt="" src={ArrowButton}  />
          </div>
        </Link>
      );
    }
    else{
      return (
        <div onClick={props.onClick} className={arrClasses.join(' ')}>
            <span>{props.label}</span>
            <img className="" alt="" src={ArrowButton}  />
        </div>
      );
    }

  }
  
export default AhButton;
  
import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLocal } from './../DocumentsLocalization';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import { mergeDeepRight } from 'ramda';
import './Bluekit.css'

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Bluekit() {

  return (
    <div className=" bg-white p-8">
      <p>Wir stellen vor: unser Kooperationspartner D+H mit der Systemlösung Bluekit! Sie möchten für maximale Sicherheit sorgen und gleichzeitig die Energiekosten im Gebäude senken, stehen aber vor dem Problem, dass die gesetzlichen Rahmenbedingungen und baulichen Gegebenheiten beachten werden müssen? Diese Herausforderung stellt oftmals die zur Entrauchung sowie Entlüftung im Aufzugsschacht angebrachten Außenöffnung dar. Unser Partner D+H hat mit seinem BlueKit-System die Lösung, um effiziente Aufzugsschachtentrauchung, intelligente Lüftung und zertifizierte Lüftungselemente zu vereinen.</p>
      <p>Mit der innovativen Aufzughelden IoT Lösung und dem D+H Bluekit System, können Sie zwei Lösungen kombinieren, um somit 7% CO2 im gesamten Gebäude einzusparen!</p>
      <p>Allein eine durchschnittlich permanent geöffnete Entrauchungsöffnung in einem fünfstöckigem Gebäude bietet je nach Heizungsart ein Energieeinsparpotential zwischen 1.500 – 2.500 EUR sowie 4.000 – 5.000 kg CO2 pro Jahr.</p>
      <p>Besuchen Sie jetzt die <a href="https://www.bluekit.de/services/kooperation-aufzughelden" target="_blank">D+H Bluekit-Seite</a> und erfahren Sie mehr über unsere Kooperation und Ihre Vorteile.</p>
      <br/>
      <div className="video-container-wrapper">
        <div className="video-container">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/9WvkFQnwNmA?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </div>
  );
}
export default Bluekit;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const autocomplete = /* GraphQL */ `
  query Autocomplete($prefix: String!, $type: String!) {
    autocomplete(prefix: $prefix, type: $type) {
      _id
      label
    }
  }
`;
export const me = /* GraphQL */ `
  query Me {
    me {
      _id
      cognitoId
      role
      label
      group
      email
      phoneNumber
      address {
        label
        street
        houseNumber
        postalCode
        city
        country
        coordinates {
          lat
          lng
        }
      }
      ... on Person {
        salutation
        firstName
        lastName
      }
      ... on Company {
        companyName
      }
    }
  }
`;
export const partyById = /* GraphQL */ `
  query PartyById($id: ID!) {
    partyById(id: $id) {
      _id
      cognitoId
      role
      label
      group
      email
      phoneNumber
      address {
        label
        street
        houseNumber
        postalCode
        city
        country
        coordinates {
          lat
          lng
        }
      }
      ... on Person {
        __typename
        salutation
        firstName
        lastName
        group
        _id
        phoneNumber
        role
        address {
          label
          street
          postalCode
          city
          country
          coordinates {
            lat
            lng
          }
        }
      }
      ... on Company {
        __typename
        companyName
        email
        group
        _id
        label
        phoneNumber
        role
        address {
          label
          street
          postalCode
          city
          country
          coordinates {
            lat
            lng
          }
        }
      }
    }
  }
`;
export const contractById = /* GraphQL */ `
  query ContractById($id: ID!) {
    contractById(id: $id) {
      _id
      status
      timeline {
        createdAt
        createdBy
        event
      }
      operator {
        _id
        label
      }
      operator_slow {
        _id
        cognitoId
        role
        label
        group
        email
        phoneNumber
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        ... on Person {
          salutation
          firstName
          lastName
        }
        ... on Company {
          companyName
        }
      }
      installations {
        _id
        label
        serialNumber
        titleByCustomer
        producer
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        formData
        costPositions {
          _id
          documentCategory
          installationId
          serialNumber
          comment
          priceInEuro
        }
        contacts {
          role
        }
      }
      documents {
        _id
        s3Key
        originalFileName
        mimeType
        size
        form {
          ... on PhotoFile {
            documentType
            categories
            selectedInstallations
          }
          ... on DocumentFile {
            documentType
            categories
            selectedInstallations
            title
            inspectionDate
          }
          ... on ContractFile {
            documentType
            categories
            selectedInstallations
            contractDuration
            signatureDate
            selectedAsPreviousContract
          }
          ... on BillFile {
            documentType
            categories
            selectedInstallations
            totalCost
            billingDate
          }
          ... on GeneratedDocumentFile {
            version
            sentAt
          }
        }
      }
      offerDetails {
        totalSum
        oldTotalSum
        specialAgreements
        contractFrom
        oldContractNumber
        cancelableOn
        noticePeriod
      }
    }
  }
`;
export const contracts = /* GraphQL */ `
  query Contracts($searchTerm: String, $page: Page!) {
    contracts(searchTerm: $searchTerm, page: $page) {
      items {
        _id
        contractNumber
        status
        timeline {
          createdAt
          createdBy
          event
        }
        operator {
          _id
          label
        }
        operator_slow {
          _id
          cognitoId
          role
          label
          group
          email
          phoneNumber
          ... on Person {
            salutation
            firstName
            lastName
          }
          ... on Company {
            companyName
          }
        }
        installations {
          _id
          label
          serialNumber
          titleByCustomer
          producer
          iotBox {
            _id
            Activation_Date_of_Customer_Dashboard__c
            Dashboard_activated__c
            Device_fabrication_number_edgebox_name__c
            Device_installed__c
            Id
            Installation_Date__c
            Installed_Device__c
            IsDeleted
            Name
          }
          address {
            label
            street
            postalCode
            city
            country
            coordinates {
              lat
              lng
            }
          }
          contacts {
            role
            party {
              _id
              cognitoId
              role
              label
              group
              email
              phoneNumber
              ... on Company {
                companyName
              }
              ... on Person {
                salutation
                firstName
                lastName
              }
            }
          }
          formData {
            Allocative_Type__c
            AssetLevel
            Audit_Interval__c
            Baujahr__c
            Box_Installationsdatum__c
            Car_Door_1_Direction__c
            Car_Door_2_Direction__c
            Car_Door_3_Direction__c
            Car_Door_4_Direction__c
            Controller_Type__c
            Controller_Variant__c
            CurrencyIsoCode
            Datum_der_Aufschaltung__c
            Description
            Elevator_Construction__c
            Elevator_Shaft_Type__c
            Elevator_Type__c
            Elevator_Variant__c
            Ground_Floor__c
            Group_Control__c
            Hersteller_des_Notrufger_ts__c
            InstallDate
            IoT_Box_Installiert__c
            Box_ID__c
            IsCompetitorProduct
            IsDeleted
            IsInternal
            Jahr_der_letzten_Modernisierung__c
            Last_Date_Of_Interim_Audit__c
            Last_Date_Of_Main_Audit__c
            Last_Date_Of_Maintenance__c
            Last_Date_Of_Re_Audit__c
            Light_Barrier__c
            Light_Grid_Curtain__c
            Load_Capacity_kg__c
            Loading_Positions__c
            Location_Engine_Room__c
            Location_Peculiarities__c
            Main_Access__c
            Maintenance_Interval__c
            Maintenance_Type__c
            Manufacturer_Controller__c
            Manufacturer_Elevator__c
            Max_Persons__c
            Modernisiert_durch_Firma__c
            Name
            Next_Date_Of_Interim_Audit__c
            Next_Date_Of_Main_Audit__c
            Next_Date_Of_Maintenance__c
            Next_Date_Of_Re_Audit__c
            Notruf_vorhanden__c
            Notrufleitstelle__c
            Number_Of_Doors__c
            Number_Of_Stops__c
            Price
            Priority__c
            PurchaseDate
            Quantity
            Rufnummer_der_Simkarte__c
            SerialNumber
            Shaft_Door_Type__c
            Speed_m_s__c
            Status
            StockKeepingUnit
            Typ_des_Notrufger_ts__c
            Type__c
            Under_Contract__c
            UsageEndDate
            Verbaute_Box__c
          }
        }
        documents {
          _id
          s3Key
          originalFileName
          mimeType
          size
          form {
            ... on PhotoFile {
              __typename
              categories
              selectedInstallations
              isInternal
            }
            ... on DocumentFile {
              __typename
              categories
              inspectionDate
              selectedInstallations
              title
              isInternal
            }
            ... on ContractFile {
              __typename
              categories
              contractDuration
              selectedAsPreviousContract
              selectedInstallations
              isInternal
            }
            ... on BillFile {
              __typename
              billingDate
              categories
              selectedInstallations
              totalCost
              isInternal
            }
            ... on GeneratedDocumentFile {
              __typename
              sentAt
              version
              isInternal
            }
          }
        }
        offerDetails {
          totalSum
          oldTotalSum
          specialAgreements
          contractFrom
          oldContractNumber
          cancelableOn
          noticePeriod
        }
      }
      after
      before
    }
  }
`;
export const contractsByFilter = /* GraphQL */ `
  query ContractsByFilter($filter: ContractFilter!, $page: Page!) {
    contractsByFilter(filter: $filter, page: $page) {
      items {
        _id
        status
        timeline {
          createdAt
          createdBy
          event
        }
        operator {
          _id
          label
        }
        operator_slow {
          _id
          cognitoId
          role
          label
          group
          email
          phoneNumber
          ... on Person {
            salutation
            firstName
            lastName
          }
          ... on Company {
            companyName
          }
        }
        installations {
          _id
          label
          serialNumber
          titleByCustomer
          producer
          formData
        }
        documents {
          _id
          s3Key
          originalFileName
          mimeType
          size
        }
        offerDetails {
          totalSum
          oldTotalSum
          specialAgreements
          contractFrom
          oldContractNumber
          cancelableOn
          noticePeriod
        }
      }
      after
      before
    }
  }
`;
export const installationById = /* GraphQL */ `
  query InstallationById($id: ID!) {
    installationById(id: $id) {
      _id
      label
      serialNumber
      producer
      address {
        label
        street
        houseNumber
        postalCode
        city
        country
        coordinates {
          lat
          lng
        }
      }
      formData
      costPositions {
        _id
        documentCategory
        installationId
        serialNumber
        comment
        priceInEuro
      }
      contacts {
        role
        party {
          _id
          cognitoId
          role
          label
          group
          email
          phoneNumber
          ... on Person {
            salutation
            firstName
            lastName
          }
          ... on Company {
            companyName
          }
        }
      }
    }
  }
`;


export const strings = {
    en: {
      loginTitle: 'Sign in',
      forgotPasswordTitle: 'Forgot Password',
      setPasswordTitle: 'Reset Password',
      loginPwdForgot: 'Forgot Password?',
      loginPwdForgotSet: 'Set Password',
      loginBackToLogin: 'Back to login',
      labelFieldUser: 'E-Mail-Adress',
      labelFieldPwd: 'Password',
      labelFieldEMailCode: 'E-Mail-Code',
      labelFieldNewPwd: 'New Password',
      labelButtonLogin: 'Login',
      labelButtonChangPwd: 'Change password',
      labelButtonSendCode: 'Send code',
      labelButtonChangePassword: 'Change Password',
      notificationMessageLoginSuccess: 'You\'ve logged in successfully!',
      notificationMessageLoginError: 'Es ist eine Fehler aufgetreten!',
      notificationMessageLoginNoRights: 'You do not have the required authorization!',
      notificationPasswordChanged :'Your password has been changed!',
      cognitoStateNotAuthorizedException: 'Wrong email address or password',
      cognitoStateLimitExceededException: 'Too many logins, please try again later!',
      cognitoStateUserNotFoundException: 'No user with this email address',
      cognitoStateCodeMismatchException: 'Unfortunately the code is not correct!',
      ruleMessageLogin: 'Please use your email address',
      ruleMessagPwd: 'Please enter your password!',
      ruleMessagEMailCode: 'Please enter the code from the email!',
      validationPwdMin: 'The password must be at least 8 characters long!',
      validationPwdLowercase: 'The password must contain small letters!',
      validationPwdUppercase: 'The password must contain capital letters!',
      validationPwdDigits: 'The password must contain numbers!',
      validationPwdSymbols: 'The password must contain special characters!',
    },
    de: {
      loginTitle: 'Anmelden',
      forgotPasswordTitle: 'Passwort vergessen',
      setPasswordTitle: 'Passwort zurücksetzen',
      loginPwdForgot: 'Passwort vergessen?',
      loginPwdForgotSet: 'Passwort zurücksetzen',
      loginBackToLogin: 'Zurück zum Login',
      labelFieldUser: 'E-Mail-Adresse',
      labelFieldPwd: 'Passwort',
      labelFieldEMailCode: 'E-Mail-Code',
      labelFieldNewPwd: 'Neues Passwort',
      labelButtonLogin: 'Login',
      labelButtonChangPwd: 'Passwort ändern',
      labelButtonSendCode: 'Code senden',
      labelButtonChangePassword: 'Passwort ändern',
      notificationMessageLoginSuccess: 'Sie haben sich erfolgreich eingeloggt!',
      notificationMessageLoginError: 'An error occurred!',
      notificationMessageLoginNoRights: 'Sie haben nicht die erforderliche Berechtigung!',
      notificationPasswordChanged :'Ihr Passwort wurde geändert!',
      cognitoStateNotAuthorizedException: 'Falsche E-Mail-Adresse oder Passwort',
      cognitoStateLimitExceededException: 'Zu viele Logins, bitte versuchen Sie es später noch einmal!',
      cognitoStateUserNotFoundException: 'Kein Benutzer unter dieser E-Mail-Adresse',
      cognitoStateCodeMismatchException: 'Der Code ist leider nicht richtig!',
      ruleMessageLogin: 'Bitte verwenden Sie ihre E-Mail-Adresse',
      ruleMessagPwd: 'Bitte geben Sie Ihr Passwort ein!',
      ruleMessagEMailCode: 'Bitte geben Sie den Code aus der E-Mail ein!',
      validationPwdMin: 'Das Passwort muss mindestens 8 Zeichen lang sein!',
      validationPwdLowercase: 'Das Passwort muss kleine Buchstaben enthalten!',
      validationPwdUppercase: 'Das Passwort muss grosse Buchstaben enthalten!',
      validationPwdDigits: 'Das Passwort muss Zahlen enthalten!',
      validationPwdSymbols: 'Das Passwort muss Sonderzeichen enthalten!',
    },
  };
import React, { createContext, useState, useContext, Dispatch, SetStateAction } from "react";
import { SmartMaintenanceContractPage } from '../graphql/API';

export interface GlobalStateInterface {
  loadAndReloadInitDone: boolean; // if a private route gets called and this is false the user data will be initated
  username: string;
  name: string;
  language : string;
  contracts : SmartMaintenanceContractPage;
  contractsAreLoaded: boolean;
}

const globalStateContext = createContext({
  state: {} as Partial<GlobalStateInterface>,
  setState: {} as Dispatch<SetStateAction<Partial<GlobalStateInterface>>>,
});

const GlobalStateProvider = ({
  children,
  value = {} as GlobalStateInterface,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}) => {
  const [state, setState] = useState(value);
  return (
    <globalStateContext.Provider value={{ state, setState }}>
      {children}
    </globalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(globalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };

import { Spin } from 'antd';
import { useEffect, useState } from 'react';

function GeneralStatistic(props:{runsPerFloor:any,doorCyclesCount:any,isLoading:boolean}) {
  
  const [generalStatistics , setGeneralStatistics] = useState<any[]>([])

  useEffect(() => {
    setGeneralStatistics(getGeneralStatistics(null,null))
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setGeneralStatistics(getGeneralStatistics(props.runsPerFloor,props.doorCyclesCount.door_cycles_count))
  }, [props.runsPerFloor,props.doorCyclesCount.door_cycles_count]);// eslint-disable-line react-hooks/exhaustive-deps

  const getGeneralStatistics = (runsPerFloor: any,door_cycles_count: string | null) =>{

    let dataToShow = []

    if(runsPerFloor){
      const keys = Object.keys(runsPerFloor);
      let count = 0
      keys.forEach((key, index) => {
        count += runsPerFloor[key].count
      });
      dataToShow.push(getElement('Fahrten',count.toString()))
    }
    else{
      dataToShow.push(getElement('Fahrten','-'))
    }
  
    if(door_cycles_count){
      dataToShow.push(getElement('Türzyklen',door_cycles_count))
    }else{
      dataToShow.push(getElement('Türzyklen','-'))
    }  
   
    return dataToShow
  }

  function getElement(label:string,value:string){
    return  <div className="flex w-full mb-4" key={label+value}><div className="w-1/2">{label}</div><div className="w-1/2">{value}</div></div>
  }

  return (
    <Spin size="large" spinning={props.isLoading}>
      <div className="font-bold mb-8">Nutzung</div>
      {generalStatistics}
    </Spin>
  );
}
export default GeneralStatistic;

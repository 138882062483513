
export function DoorSouth(props:{southIsOpen:boolean}) {
    return (
        <svg viewBox="0 0 188 122" version="1.1" className={props.southIsOpen ? 'doorAnimation_south_door door_south_open' : 'doorAnimation_south_door'}  >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="bottomBar" transform="translate(43.610000, 94.160000)" fillRule="nonzero">
                    <polygon id="Path" fill="#8D8E8E" points="6.76 18.44 4.48 17.2 94.01 0.89 96.29 2.13"></polygon>
                    <polygon id="Path" fill="#8D8E8E" points="2.29 19.25 0.01 18.01 4.48 17.2 6.76 18.44"></polygon>
                    <polygon id="Path" fill="#737474" points="2.28 23.98 0 22.74 0.01 18.01 2.29 19.25"></polygon>
                    <polygon id="Path" fill="#A2A3A3" points="96.29 2.13 100.75 1.24 100.74 6.13 2.28 23.98 2.29 19.25 6.76 18.44"></polygon>
                    <polygon id="Path" fill="#8D8E8E" points="96.29 2.13 94.01 0.89 98.47 0 100.75 1.24"></polygon>
                </g>
                <g className="doorRight" transform="translate(137.620000, 1.220000)" fillRule="nonzero">
                    <polygon id="Path" fill="#449384" points="3.01 93.89 0.73 92.65 46.84 84.29 49.12 85.53"></polygon>
                    <polygon id="Path" fill="#35766A" points="49.12 85.53 46.84 84.29 46.99 1.05 49.27 2.29"></polygon>
                    <polygon id="Path" fill="#B4B4B4" points="3.17 6.89 0.89 5.65 46.99 1.05 49.27 2.29"></polygon>
                    <polygon id="Path" fill="#449384" points="6.9 5.56 4.62 4.32 47.88 0 50.16 1.24"></polygon>
                    <polygon id="Path" fill="#DFDFDF" points="3.17 6.89 49.27 2.29 49.12 85.53 3.01 93.89"></polygon>
                    <polygon id="Path" fill="#828282" points="3.01 93.89 0.73 92.65 0.89 5.65 3.17 6.89"></polygon>
                    <path d="M6.9,5.56 L50.16,1.24 L50,86.32 L6.73,94.17 L2.28,94.98 L2.28,6.01 L6.9,5.56 Z M49.12,85.53 L49.27,2.3 L3.17,6.9 L3.01,93.9 L49.12,85.53" id="Shape" fill="#4FAA99"></path>
                    <polygon id="Path" fill="#449384" points="2.28 6.02 0 4.78 4.62 4.32 6.9 5.56"></polygon>
                    <polygon id="Path" fill="#35766A" points="2.28 94.99 0 93.75 0 4.78 2.28 6.02"></polygon>
                </g>
                <g className="doorLeft" transform="translate(-0.000000, 15.000000)" fillRule="nonzero">
                    <polygon id="Path" fill="#449384" points="3.17 105.42 0.89 104.18 47.01 95.81 49.28 97.05"></polygon>
                    <polygon id="Path" fill="#35766A" points="49.28 97.05 47.01 95.81 47.18 1.05 49.46 2.29"></polygon>
                    <polygon id="Path" fill="#B4B4B4" points="3.36 6.89 1.08 5.65 47.18 1.05 49.46 2.29"></polygon>
                    <polygon id="Path" fill="#828282" points="3.17 105.42 0.89 104.18 1.08 5.65 3.36 6.89"></polygon>
                    <polygon id="Path" fill="#DFDFDF" points="3.36 6.89 49.46 2.29 49.28 97.05 3.17 105.42"></polygon>
                    <polygon id="Path" fill="#35766A" points="2.28 106.54 0 105.3 0.19 4.77 2.47 6.01"></polygon>
                    <path d="M45.88,1.69 L50.35,1.25 L50.17,97.78 L45.7,98.67 L2.28,106.54 L2.47,6.01 L45.88,1.69 Z M49.28,97.05 L49.46,2.29 L3.36,6.89 L3.18,105.42 L49.28,97.05" id="Shape" fill="#4FAA99"></path>
                    <polygon id="Path" fill="#449384" points="2.47 6.01 0.19 4.77 43.6 0.45 45.88 1.69"></polygon>
                    <polygon id="Path" fill="#449384" points="45.88 1.69 43.6 0.45 48.07 0 50.35 1.24"></polygon>
                </g>
                <g id="topbar" transform="translate(43.800000, 0.660000)" fillRule="nonzero">
                    <polygon id="Path" fill="#A2A3A3" points="2.29 11.04 100.73 1.24 100.72 6.12 96.27 6.56 6.75 15.4 2.28 15.78"></polygon>
                    <polygon id="Path" fill="#8D8E8E" points="2.29 11.04 0.01 9.8 98.45 0 100.73 1.24"></polygon>
                    <polygon id="Path" fill="#737474" points="2.28 15.78 -7.10542736e-15 14.54 0.01 9.8 2.29 11.04"></polygon>
                </g>
            </g>
        </svg>
    );
}


export function DoorNorthSouth(props:{southIsOpen:boolean,northIsOpen:boolean}) {
    let styles = 'doorAnimation_north_south'
    styles += props.southIsOpen?  ' door_south_open' :  ' '
    styles += props.northIsOpen?  ' door_north_open' :  ' '

    return (
        <svg viewBox="0 0 215 158" version="1.1" className={styles} >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="doorSouth" transform="translate(1.000000, 0.660000)" fillRule="nonzero" className="doorNorth">
                    <g id="bottomBar" transform="translate(43.610000, 93.500000)">
                        <polygon id="Path" fill="#8D8E8E" points="6.76 18.44 4.48 17.2 94.01 0.89 96.29 2.13"></polygon>
                        <polygon id="Path" fill="#8D8E8E" points="2.29 19.25 0.01 18.01 4.48 17.2 6.76 18.44"></polygon>
                        <polygon id="Path" fill="#737474" points="2.28 23.98 0 22.74 0.01 18.01 2.29 19.25"></polygon>
                        <polygon id="Path" fill="#A2A3A3" points="96.29 2.13 100.75 1.24 100.74 6.13 2.28 23.98 2.29 19.25 6.76 18.44"></polygon>
                        <polygon id="Path" fill="#8D8E8E" points="96.29 2.13 94.01 0.89 98.47 0 100.75 1.24"></polygon>
                    </g>
                    <g id="rightDoor" className="doorRight" transform="translate(137.620000, 0.560000)">
                        <polygon id="Path" fill="#449384" points="3.01 93.89 0.73 92.65 46.84 84.29 49.12 85.53"></polygon>
                        <polygon id="Path" fill="#35766A" points="49.12 85.53 46.84 84.29 46.99 1.05 49.27 2.29"></polygon>
                        <polygon id="Path" fill="#B4B4B4" points="3.17 6.89 0.89 5.65 46.99 1.05 49.27 2.29"></polygon>
                        <polygon id="Path" fill="#449384" points="6.9 5.56 4.62 4.32 47.88 0 50.16 1.24"></polygon>
                        <polygon id="Path" fill="#DFDFDF" points="3.17 6.89 49.27 2.29 49.12 85.53 3.01 93.89"></polygon>
                        <polygon id="Path" fill="#828282" points="3.01 93.89 0.73 92.65 0.89 5.65 3.17 6.89"></polygon>
                        <path d="M6.9,5.56 L50.16,1.24 L50,86.32 L6.73,94.17 L2.28,94.98 L2.28,6.01 L6.9,5.56 Z M49.12,85.53 L49.27,2.3 L3.17,6.9 L3.01,93.9 L49.12,85.53" id="Shape" fill="#4FAA99"></path>
                        <polygon id="Path" fill="#449384" points="2.28 6.02 0 4.78 4.62 4.32 6.9 5.56"></polygon>
                        <polygon id="Path" fill="#35766A" points="2.28 94.99 0 93.75 0 4.78 2.28 6.02"></polygon>
                    </g>
                    <g id="leftDoor" className="doorLeft" transform="translate(-0.000000, 14.340000)">
                        <polygon id="Path" fill="#449384" points="3.17 105.42 0.89 104.18 47.01 95.81 49.28 97.05"></polygon>
                        <polygon id="Path" fill="#35766A" points="49.28 97.05 47.01 95.81 47.18 1.05 49.46 2.29"></polygon>
                        <polygon id="Path" fill="#B4B4B4" points="3.36 6.89 1.08 5.65 47.18 1.05 49.46 2.29"></polygon>
                        <polygon id="Path" fill="#828282" points="3.17 105.42 0.89 104.18 1.08 5.65 3.36 6.89"></polygon>
                        <polygon id="Path" fill="#DFDFDF" points="3.36 6.89 49.46 2.29 49.28 97.05 3.17 105.42"></polygon>
                        <polygon id="Path" fill="#35766A" points="2.28 106.54 0 105.3 0.19 4.77 2.47 6.01"></polygon>
                        <path d="M45.88,1.69 L50.35,1.25 L50.17,97.78 L45.7,98.67 L2.28,106.54 L2.47,6.01 L45.88,1.69 Z M49.28,97.05 L49.46,2.29 L3.36,6.89 L3.18,105.42 L49.28,97.05" id="Shape" fill="#4FAA99"></path>
                        <polygon id="Path" fill="#449384" points="2.47 6.01 0.19 4.77 43.6 0.45 45.88 1.69"></polygon>
                        <polygon id="Path" fill="#449384" points="45.88 1.69 43.6 0.45 48.07 0 50.35 1.24"></polygon>
                    </g>
                    <g id="topbar" transform="translate(43.800000, 0.000000)">
                        <polygon id="Path" fill="#A2A3A3" points="2.29 11.04 100.73 1.24 100.72 6.12 96.27 6.56 6.75 15.4 2.28 15.78"></polygon>
                        <polygon id="Path" fill="#8D8E8E" points="2.29 11.04 0.01 9.8 98.45 0 100.73 1.24"></polygon>
                        <polygon id="Path" fill="#737474" points="2.28 15.78 -7.10542736e-15 14.54 0.01 9.8 2.29 11.04"></polygon>
                    </g>
                </g>
                <g id="doorNorth" transform="translate(27.000000, 36.660000)" fillRule="nonzero" className="doorSouth">
                    <g id="bottomBar" transform="translate(43.610000, 93.500000)">
                        <polygon id="Path" fill="#8D8E8E" points="6.76 18.44 4.48 17.2 94.01 0.89 96.29 2.13"></polygon>
                        <polygon id="Path" fill="#8D8E8E" points="2.29 19.25 0.01 18.01 4.48 17.2 6.76 18.44"></polygon>
                        <polygon id="Path" fill="#737474" points="2.28 23.98 0 22.74 0.01 18.01 2.29 19.25"></polygon>
                        <polygon id="Path" fill="#A2A3A3" points="96.29 2.13 100.75 1.24 100.74 6.13 2.28 23.98 2.29 19.25 6.76 18.44"></polygon>
                        <polygon id="Path" fill="#8D8E8E" points="96.29 2.13 94.01 0.89 98.47 0 100.75 1.24"></polygon>
                    </g>
                    <g id="rightDoor" className="doorRight" transform="translate(137.620000, 0.560000)">
                        <polygon id="Path" fill="#449384" points="3.01 93.89 0.73 92.65 46.84 84.29 49.12 85.53"></polygon>
                        <polygon id="Path" fill="#35766A" points="49.12 85.53 46.84 84.29 46.99 1.05 49.27 2.29"></polygon>
                        <polygon id="Path" fill="#B4B4B4" points="3.17 6.89 0.89 5.65 46.99 1.05 49.27 2.29"></polygon>
                        <polygon id="Path" fill="#449384" points="6.9 5.56 4.62 4.32 47.88 0 50.16 1.24"></polygon>
                        <polygon id="Path" fill="#DFDFDF" points="3.17 6.89 49.27 2.29 49.12 85.53 3.01 93.89"></polygon>
                        <polygon id="Path" fill="#828282" points="3.01 93.89 0.73 92.65 0.89 5.65 3.17 6.89"></polygon>
                        <path d="M6.9,5.56 L50.16,1.24 L50,86.32 L6.73,94.17 L2.28,94.98 L2.28,6.01 L6.9,5.56 Z M49.12,85.53 L49.27,2.3 L3.17,6.9 L3.01,93.9 L49.12,85.53" id="Shape" fill="#4FAA99"></path>
                        <polygon id="Path" fill="#449384" points="2.28 6.02 0 4.78 4.62 4.32 6.9 5.56"></polygon>
                        <polygon id="Path" fill="#35766A" points="2.28 94.99 0 93.75 0 4.78 2.28 6.02"></polygon>
                    </g>
                    <g id="leftDoor" className="doorLeft" transform="translate(-0.000000, 14.340000)">
                        <polygon id="Path" fill="#449384" points="3.17 105.42 0.89 104.18 47.01 95.81 49.28 97.05"></polygon>
                        <polygon id="Path" fill="#35766A" points="49.28 97.05 47.01 95.81 47.18 1.05 49.46 2.29"></polygon>
                        <polygon id="Path" fill="#B4B4B4" points="3.36 6.89 1.08 5.65 47.18 1.05 49.46 2.29"></polygon>
                        <polygon id="Path" fill="#828282" points="3.17 105.42 0.89 104.18 1.08 5.65 3.36 6.89"></polygon>
                        <polygon id="Path" fill="#DFDFDF" points="3.36 6.89 49.46 2.29 49.28 97.05 3.17 105.42"></polygon>
                        <polygon id="Path" fill="#35766A" points="2.28 106.54 0 105.3 0.19 4.77 2.47 6.01"></polygon>
                        <path d="M45.88,1.69 L50.35,1.25 L50.17,97.78 L45.7,98.67 L2.28,106.54 L2.47,6.01 L45.88,1.69 Z M49.28,97.05 L49.46,2.29 L3.36,6.89 L3.18,105.42 L49.28,97.05" id="Shape" fill="#4FAA99"></path>
                        <polygon id="Path" fill="#449384" points="2.47 6.01 0.19 4.77 43.6 0.45 45.88 1.69"></polygon>
                        <polygon id="Path" fill="#449384" points="45.88 1.69 43.6 0.45 48.07 0 50.35 1.24"></polygon>
                    </g>
                    <g id="topbar" transform="translate(43.800000, 0.000000)">
                        <polygon id="Path" fill="#A2A3A3" points="2.29 11.04 100.73 1.24 100.72 6.12 96.27 6.56 6.75 15.4 2.28 15.78"></polygon>
                        <polygon id="Path" fill="#8D8E8E" points="2.29 11.04 0.01 9.8 98.45 0 100.73 1.24"></polygon>
                        <polygon id="Path" fill="#737474" points="2.28 15.78 -7.10542736e-15 14.54 0.01 9.8 2.29 11.04"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );

}

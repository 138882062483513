import Tile from './index'
import icon from './../../assets/images/icons/installations.svg'
import { useGlobalState,  } from "../../utils/globlStateProvider";
import { strings as stringLocal} from './TileLocalization';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import { I18n } from '@aws-amplify/core';
import { MaintenanceContractStatus } from '../../graphql/API';
import { Spin } from 'antd';

I18n.putVocabularies(stringLocal);
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function TileIntallations(props:{className?:string}) {
  const { state } = useGlobalState();

  const getCountInstallations = () => {
    let countInstallations = 0
    let arrValidContractStates: MaintenanceContractStatus[] = ['ReadyToAccept','Accepted']
    state.contracts?.items?.forEach(element => {
      if( 
        element?.status 
        && arrValidContractStates.includes(element?.status) 
        && element?.installations?.length 
        && element?.installations?.length > 0){
          countInstallations += element.installations?.length
      }
    });

    return countInstallations
  }
  
  var content = <div className="text-5xl pl-16">{ state.contractsAreLoaded? getCountInstallations() : <Spin size="large" /> }</div>
  
  return (
      <Tile icon={icon} headline={I18n.get('titleInstallations')} content={content} innerLink='/installations' className={props.className} />
  );
}
  
export default TileIntallations;
import ReactEChartsCore from 'echarts-for-react/lib/core';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import { Spin } from 'antd';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
  SankeyChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import { useEffect, useState } from 'react';


//I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
//I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function TurnsBetweenLevelsChart(props:{data:any,isLoading:boolean,device:any}) {
  
  interface dataItem {
    label:string,
    from:string,
    to:string,
    value:string
  };

  interface result {
    data: Array<dataItem>,
    topMaxValue?:string
  };

  const [chartLabels, setChartLabels] = useState([{}])
  const [chartData, setChartData] = useState([{}])
  const [noData, setNoData] = useState(false)

  echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, CanvasRenderer, LegendComponent, SankeyChart]
  );
  
  echarts.registerTheme('light', {
    backgroundColor: '#fff'
  });

  const fourColors = [
        '#0074B0',
        '#349CD2',
        '#6EB5DA',
        '#98C6DE'
    ];

  useEffect(() => {
    let lobbyFloor = 0
    let reportedFloors = 255

    if(props.device.lobbyFloor){
      lobbyFloor = parseInt(props.device.lobbyFloor)
    }

    if(props.device.reportedFloors){
      reportedFloors = parseInt(props.device.reportedFloors)
    }

    let data = props.data
    let myResult = {data: []} as result;
    let arrayOfObjects = Object.values(data)
    if(arrayOfObjects.length > 0){

    // prepare datapoints
    arrayOfObjects.forEach(( element ) => {
        const dataPoint = element as {startFloor:number,endFloor:number, value:number}
        if (dataPoint.startFloor !== 255 && dataPoint.endFloor !== 255) {
          myResult.data?.push({
                label: getHumanReadableFloorLabel(dataPoint.startFloor,lobbyFloor) + ' - ' + getHumanReadableFloorLabel(dataPoint.endFloor,lobbyFloor),
                from: getHumanReadableFloorLabel(dataPoint.startFloor,lobbyFloor).toString(),
                to: getHumanReadableFloorLabel(dataPoint.endFloor,lobbyFloor).toString(),
                value: dataPoint.value.toString()
            });
        }
    });

    /*
    // Sort data for popular runs.
    myResult.data = myResult.data.sort((a = {}, b = {}) => {
        return b.value - a.value;
    });
    */

    let dataLinks = []
    for(let i = 0 ; i< arrayOfObjects.length; i++){
      if(myResult.data[i].from !== myResult.data[i].to){
          dataLinks.push({
              source: myResult.data[i].from+'_in',
              target: myResult.data[i].to+'_out',
              value: myResult.data[i].value
          })
      }
    }

    setChartData(dataLinks)

    // prepare labels
    const dataLabels = []
    const popularRuns = Array.isArray(dataLinks) ? dataLinks.slice(0, 15) : [];
    const colours = popularRuns.map((value, index) => fourColors[index%2?1:0]);
    for(let i = 0 ; i < reportedFloors ; i++){
     if( floorIncludedInSourceData(getHumanReadableFloorLabel(i,lobbyFloor),myResult.data)){
            dataLabels.push({
                name:getHumanReadableFloorLabel(i,lobbyFloor)+'_in',
                itemStyle: {
                    color: colours[i],
                    borderColor: colours[i]
                },
                depth:0
            })
     }
    if( floorIncludedInTargetData(getHumanReadableFloorLabel(i,lobbyFloor),myResult.data)){
            dataLabels.push({
                name:getHumanReadableFloorLabel(i,lobbyFloor)+'_out',
                itemStyle: {
                    color: colours[i],
                    borderColor: colours[i]
                },
                depth:1
            })
        }
    } 

    setChartLabels(dataLabels)
    setNoData(false)
  }
  else{
    setNoData(true)
  }
}, [props.data,props.device]);

  function  getHumanReadableFloorLabel(floor: number,lobbyFloor: number){
    if(!isNaN(parseInt(lobbyFloor.toString()))){
        if(floor === lobbyFloor){
            floor = 0
        }
        else if(floor > lobbyFloor){
            floor -= lobbyFloor
        }
        else if(floor < lobbyFloor){
            let deepFloors = Array.from(Array(parseInt(lobbyFloor.toString())).keys()).reverse()
            floor = (deepFloors[floor]+1)*-1
        }
    }
    return floor
  }

  function floorIncludedInSourceData(floor: any, chartData: any[] ){
        let foundFloor = false
        for(let i = 0 ; i< chartData.length; i++){
            if(floor === parseInt(chartData[i].from)){
                foundFloor = true
            }
        }
        return foundFloor
    }

    function floorIncludedInTargetData(floor: any, chartData: any[] ){
        let foundFloor = false
        for(let i = 0 ; i< chartData.length; i++){
            if(floor === parseInt(chartData[i].to)){
                foundFloor = true
            }
        }
        return foundFloor
    }   

  var option = {
    series: {
        type: 'sankey',
        draggable:false,
        layoutIterations:0,
        left: 10.0,
        top: 20.0,
        right: 17.0,
        bottom: 25.0,
        data: chartLabels.reverse(),
        links: chartData,
        lineStyle: {
            color: 'source',
            curveness: 0.5
        },
        itemStyle: {
            color: '#1f77b4',
            borderColor: '#1f77b4'
        },
        label: {
            color: 'rgba(0,0,0,0.7)',
            fontFamily: 'Arial',
            fontSize: 10,
            formatter: (function(value: { name: string; }){
                if(value.name){
                    return value.name.split('_')[0]
                }
            })
        }
    },
    tooltip: {
        trigger: 'item',// 'item|axis|none'
        triggerOn: 'mousemove',
        formatter: (function(data: { value: number; }){
            if(data && data.value){
                if(data.value <= 0){
                    return '-'
                }
                else{
                    return String(data.value)
                }
            }
            else{
                return '-'
            }
        })
    }
};

  return (
    <>
    {noData && <div className="flex" style={{'height':'300px'}}><div className="m-auto">keine Daten für den gewählten Zeitraum</div></div>}
    {!noData && 
      <Spin size="large" spinning={props.isLoading}>
      <ReactEChartsCore
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={"light"}
            showLoading={false}
            style={{height: '300px', width: '100%'}}
          />
    </Spin>}
    </>
  );
}
export default TurnsBetweenLevelsChart;

import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLocal } from './../DocumentsLocalization';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import { mergeDeepRight } from 'ramda';
import { useGlobalState } from "../../utils/globlStateProvider";
import { DocumentRef, Installation, SmartMaintenanceContract } from '../../graphql/API';
import React, { useEffect, useState } from 'react';
import { getContractByInstallation, getGeneratedContractDocumentByContract, openDocument } from '../../utils/helpers';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import './ContractWin.css';
import contractDummyBig from './../../assets/images/contract_dummy_big.png'
import { Button } from 'antd';
import { DownloadOutlined,SearchOutlined } from '@ant-design/icons';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Documents(props:{installation:Installation | undefined}) {
  
  const { state } = useGlobalState()
  const [contract, setContract] = useState<SmartMaintenanceContract | null>(null)
  const [contractFile, setContractFile] = useState<DocumentRef | undefined>(undefined)
  
  useEffect(() => {
    let contract = getContractByInstallation(props.installation,state.contracts)
    setContract(contract)
    setContractFile(getGeneratedContractDocumentByContract(contract))
  }, [state.contractsAreLoaded,props.installation]);// eslint-disable-line react-hooks/exhaustive-deps

  const getContractdata = () => {
    let created: String | undefined = undefined
    contract?.timeline?.forEach( itemTimeline => {
      if(itemTimeline.event === 'ContractReleased'){
        created = moment(itemTimeline.createdAt).format('L')
      }
    })

    return <>
      <div className="flex listItem">
        <div>Angebot erstellt am</div>
        <div>{created}</div>
      </div>
      <div className="flex listItem">
        <div>Status</div>
        <div>{(contract?.status === 'ReadyToAccept')? <span className=" text-warnRed">Nicht abgeschlossen</span> : 'Abgeschlossen'}</div>
      </div>
    </>
  }
  
  return (
    <div className=" bg-white pt-8 pl-8 pr-8 pb-16">
      {(contract?.status === 'ReadyToAccept')? <h6 className=" text-warnRed pb-10">Vertrag nicht abgeschlossen</h6> : <h6 className="pb-10">Vertrag abgeschlossen</h6>}
      <div className="flex contractContainer flex-col m:flex-row flex-wrap">
        <div className=" flex-grow pr-0  w-full m:w-1/2 m:pr-5 xl:pr-20 xl:w-1/3">
          <span className="title">Vertragsdaten</span>
          {getContractdata()}
          <div className="text-center  mt-4 mb-4 xl:hidden">
            <Button onClick={()=>openDocument({key:contractFile?.s3Key,originalFileName:contractFile?.originalFileName},true)}  className=" mt-4" type="primary" icon={<DownloadOutlined />} size="large">
              Vertrag herunterladen
            </Button>
          </div>
        </div>
        <div className=" hidden flex-grow pr-0 l:pr-20 w-full xl:w-1/3 xl:inline-block">
          <div className="flex">
            <div className="text-right w-full mt-4 mb-4 xl:mt-0">
              <img src={contractDummyBig} onClick={()=>openDocument({key:contractFile?.s3Key,originalFileName:contractFile?.originalFileName},false)}  className="contracDummyBig hidden xl:inline-block cursor-pointer" alt="Ihr Vertrag" />
              <Button onClick={()=>openDocument({key:contractFile?.s3Key,originalFileName:contractFile?.originalFileName},true)} className="contractDownload mt-4" type="primary" icon={<DownloadOutlined />} size="large">
                Vertrag herunterladen
              </Button>
            </div>
            <SearchOutlined onClick={()=>openDocument({key:contractFile?.s3Key,originalFileName:contractFile?.originalFileName},false)} className="serachIcon self-end ml-2 hidden xl:inline-block" style={{fontSize:36}} />
          </div>
        </div>
        <div className=" flex-grow pr-0 w-full  m:w-1/2 xl:pr-20 xl:w-1/3 ">
          <span className="title block mb-4">Rücksendeoptionen</span>
          <div className="hint mb-8">Bitte senden Sie uns Ihren Vertrag <strong>unterschrieben</strong> über die nachfolgenden Möglichkeiten zurück.</div>
          <div className="flex listItem">
            <div>E-Mail</div>
            <div>vertrag@aufzugehelden.com </div>
          </div>
          <div className="flex listItem">
            <div>Post</div>
            <div>Aufzughelden<br/>
              Digital Spine GmbH<br/>
              Zionskirchstraße 73 a<br/>
              D-10119 Berlin</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Documents;

import * as React from 'react';
import { Link,useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form , Spin, Input, Button, notification } from 'antd';
import { NumberOutlined ,LockFilled,LoadingOutlined } from '@ant-design/icons';
import logo from './../../assets/images/logo_aufzughelden.svg'
import './LoginForm.css'
import ArrowButton from './../../assets/images/arrow_button.svg'
import {validateNextPassword} from './../../utils/validators'
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLoginForm } from './LoginFormLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import Footer from '../Footer';

I18n.putVocabularies(mergeDeepRight(stringLoginForm,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

const ForgotPasswordCodeContainer = () => {
  let history = useHistory();
  const [isloading, setisLoading] = React.useState(false)

  const onFinish = (values: any) => {
    setisLoading(true)
    let { code, password,  } = values;
    
    let username = history.location.search.split('=')[1];

    Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
    .then( user => {
      
      notification.success({
        message: I18n.get('notificationSuccess'),
        description: I18n.get('notificationPasswordChanged'),
        placement: 'topRight',
        duration: 5
      });

      history.push('/')
    })
    .catch(err => {
      console.log(err.code)
      var message = ''
      switch (err.code) {
        case "CodeMismatchException":
          message = I18n.get('cognitoStateCodeMismatchException')
          break;
        default:
          message = err.message
          break;
      }

      notification.error({
        message: I18n.get('notificationError'),
        description: message,
        placement: 'topRight'
      });

      setisLoading(false)
    });
    
  };

  const onFinishFailed = (errorInfo: any) => {
    setisLoading(false)
    console.log('Failed:', errorInfo);
  };

    return (
      <React.Fragment>
        <div className="pt-16 m:pt-48">
          <img src={logo} alt="Augzuhelden Logo" className="m-auto h-20 m:h-28 mb-12"/>
        </div>
        <div className="loginForm bg-white m-auto">
          <h4 className="text-left pb-4">{I18n.get('setPasswordTitle')}</h4>
          <Form 
            requiredMark={'optional'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="login-form">
            <Form.Item
              name="code"
              label={I18n.get('labelFieldEMailCode')}
              labelAlign="left"            
              labelCol={{span:24}}
              wrapperCol={{span:24}}
              rules={[{ required: true, type: 'string', min: 6 ,message:I18n.get('ruleMessagEMailCode') }]} >
              <Input placeholder="Code" size="large" allowClear prefix={<NumberOutlined />} />
            </Form.Item>

            <Form.Item
              name="password"
              label={I18n.get('labelFieldNewPwd')}
              labelAlign="left"
              labelCol={{span:24}}
              rules={[{ required: true, validator: validateNextPassword }]} >
              <Input placeholder={I18n.get('labelFieldPwd')} autoComplete="current-password" type="password" allowClear prefix={<LockFilled/>}/>
            </Form.Item>

            <Form.Item className="text-center">
              <div className=" text-left pt-4">
                <div>
                  <Button
                    //type="primary"
                    disabled={isloading}
                    htmlType="submit"
                    className= {isloading ? 'sbButton isLoading' : 'sbButton isNotLoading'}
                  >
                    {isloading ? <Spin indicator={<LoadingOutlined/>} /> : <>{I18n.get('labelButtonChangePassword')} <img className="" alt="" src={ArrowButton}  /></>}
                  </Button>
                </div>
                <div className=" mt-1"><Link className="login-form-forgot text-blueLight" to="/login">{I18n.get('loginBackToLogin')}</Link></div>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div className="flex-grow"></div>
        <Footer />
      </React.Fragment>
    );
  }

export default ForgotPasswordCodeContainer;
import { handleLogout } from './../../utils/helpers';
import { useEffect } from 'react';

const LogoutContainer = () => {
  
  useEffect(() => {
    handleLogout()
  }, []);

    return (
      <>Sie werden in wenigen Sekunden weitergeleitet.</>
    );
  }

export default LogoutContainer;
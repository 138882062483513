import Tile from './index'
import {stylesTile} from './index'
import icon from './../../assets/images/icons/edit.svg'

function TileIntallations(props:{isSmall?:boolean}) {
    var headline = 'Neue Service-Anfrage anlegen'
    return (
        <Tile icon={icon} headline={headline} content={null} isSmall={props.isSmall} style={stylesTile.warnRed} />
    );
  }
  
export default TileIntallations;
  
import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../utils/globalLocalization';
import { strings as stringLocal } from './DocumentsLocalization';
import { USER_CHOICE_LANGUAGE } from './../utils/constants';
import { mergeDeepRight } from 'ramda';
import { Button, Popover } from 'antd'
import { useGlobalState, GlobalStateInterface } from "../utils/globlStateProvider";
import { API, GraphQLResult } from '@aws-amplify/api'
import { MaintenanceContractStatus, Query, SmartMaintenanceContractPage } from '../graphql/API';
import * as queries from './../graphql/queries';
import React, { useEffect } from 'react';
import TableContracts from './Tables/DocumentsTableContracts'
import TableBills from './Tables/DocumentsTableBills'
import TableManuals from './Tables/DocumentsTableManuals'
import TableLogs from './Tables/DocumentsTableLogs'
import TableDrawings from './Tables/DocumentsTableDrawings'
import TablePhotos from './Tables/DocumentsTablePhotos'
import TableOthers from './Tables/DocumentsTableOthers'
import TableInspectionCertificates from './Tables/DocumentsTableInspectionCertificates'
import {DocumentsType} from './Tables/Types'
import './Documents.css'
import { isBill, isContract, isDocument, isPhoto } from '../utils/helpers';
import { useLocation } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Documents() {
  
  const { state, setState } = useGlobalState()
  const [typeFilter, setTypeFilter] = React.useState('contracts')
  const tmpDocuments:DocumentsType = {contracts:[],bills:[],inspectionCertificats:[],manuals:[],logbooks:[],technicaDrawings:[],others:[],photos:[]}
  const location = useLocation();

  const loadContracts = async() => {
    const res = await (API.graphql({ query: queries.contracts , variables:{ page:{}, filter:{} }}) as GraphQLResult<Query> );
    if(res.data?.contracts?.items != null){
      let dataGlobal:Partial<GlobalStateInterface> = {
        contracts: res.data?.contracts as SmartMaintenanceContractPage,
        contractsAreLoaded:true
      } 
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }
  if(!state.contractsAreLoaded){ loadContracts() }

  /*
  if(!state.contractsAreLoaded && !state.contracts?.before){ 
    promiseLoadContracts.then( ( dataGlobal ) => {
      if(dataGlobal && typeof dataGlobal === 'object'){
        setState((prev) => ({ ...prev, ...dataGlobal }));
      }
    })
  }
  */
  const [documents, setDocuments] =React.useState<DocumentsType | undefined>();

  useEffect(() => {

    state.contracts?.items?.forEach( itemContract => {

      let arrValidContractStates: MaintenanceContractStatus[] = ['ReadyToAccept','Accepted']
      if(itemContract?.status 
        && arrValidContractStates.includes(itemContract?.status) ){

          itemContract?.documents?.forEach( itemDocument => {
            if(itemDocument?.form){
              if(itemDocument.form.isInternal){
                // dont show internal files
                // with the new api this needs to get handled by the api
              }else if(  isContract(itemDocument.form) ){
                tmpDocuments.contracts.push(itemDocument)
              }
              else if(  isBill(itemDocument.form) ){
                tmpDocuments.bills.push(itemDocument)
              }
              else if(  isPhoto(itemDocument.form) ){
                tmpDocuments.photos.push(itemDocument)
              }
              else if(  isDocument(itemDocument.form) ){
                // dc-manual | dc-photo | dc-technical-drawing
                // collect by categorie , if others left than the above ones than collect also to "others"
                var index = 0
                let categories = [...itemDocument.form.categories]
                if(categories.includes("dc-technical-drawing")){
                  tmpDocuments.technicaDrawings.push(itemDocument)
                  index = categories.indexOf("dc-technical-drawing");
                  if (index !== -1) {
                    categories.splice(index, 1);
                  }
                }
                if(categories.includes("dc-manual")){
                  tmpDocuments.manuals.push(itemDocument)
                  index = categories.indexOf("dc-manual");
                  if (index !== -1) {
                    categories.splice(index, 1);
                  }
                }
                if(categories.includes("dc-log")){
                  tmpDocuments.logbooks.push(itemDocument)
                  index = categories.indexOf("dc-log");
                  if (index !== -1) {
                    categories.splice(index, 1);
                  }
                }
                if(categories.includes("dc-inspection-certificate")){
                  tmpDocuments.inspectionCertificats.push(itemDocument)
                  index = categories.indexOf("dc-inspection-certificate");
                  if (index !== -1) {
                    categories.splice(index, 1);
                  }
                }
                if(categories.length > 0){
                  tmpDocuments.others.push(itemDocument)
                }          
              }
            }
          })
          
        }
      setDocuments(tmpDocuments)
    })

  }, [state.contractsAreLoaded]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set category onload
    if(location.pathname === '/documents/bills'){
      setTypeFilter('bills')
    }
    else if(location.pathname === '/documents/contracts'){
      setTypeFilter('contracts')
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const getFilter=()=>{
    let buttonOthers = <></>
    if(documents && documents?.others.length > 0){
      buttonOthers = <Button type={typeFilter === 'notAStandardCategory'? "primary" : "default"} onClick={()=>setTypeFilter('notAStandardCategory')} >Andere</Button> 
    }

    return (
      <div className="categoryFilter">
        <Button type={typeFilter === 'contracts'? "primary" : "default"} onClick={()=>setTypeFilter('contracts')} >Verträge</Button>
        <Button type={typeFilter === 'bills'? "primary" : "default"} onClick={()=>setTypeFilter('bills')} >Rechnungen</Button>
        <Button type={typeFilter === 'inspection'? "primary" : "default"} onClick={()=>setTypeFilter('inspection')} >Prüfbescheinigungen</Button>
        <Button type={typeFilter === 'manuals'? "primary" : "default"} onClick={()=>setTypeFilter('manuals')} >Handbücher</Button>
        <Button type={typeFilter === 'logs'? "primary" : "default"} onClick={()=>setTypeFilter('logs')} >Logbücher</Button>
        <Button type={typeFilter === 'draws'? "primary" : "default"} onClick={()=>setTypeFilter('draws')} >Schaltpläne</Button>
        {buttonOthers}
        <Button type={typeFilter === 'photos'? "primary" : "default"} onClick={()=>setTypeFilter('photos')} >Fotos</Button>     
      </div>
      )
  }

  const getTableByFilter = () => {
    if(typeFilter === 'contracts'){
      return <TableContracts documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'bills'){
      return <TableBills documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'inspection'){
      return <TableInspectionCertificates documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'manuals'){
      return <TableManuals documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'logs'){
      return <TableLogs documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'draws'){
      return <TableDrawings documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'photos'){
      return <TablePhotos documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'notAStandardCategory'){
      return <TableOthers documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }

    

    return <>incorrect Filtertype</>
  }

  const popOvercontent = (
    <div>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    </div>
  );

  return (
    <section className="Content bg-greyLighter">
      <div>
        <h3 className="pb-9 flex place-items-start">
          {I18n.get('titleDocuments')}
          <Popover 
            placement="bottom"
            content={popOvercontent}
            title="Meine Anlagen"
            trigger="click"
            className="pl-2 hidden"
            overlayClassName="headlinePopover"
            >
            <InfoCircleOutlined style={{ fontSize:16}}/>
          </Popover>
        </h3>
        <div className="subPageContentContainer">
          <div className="innerElement">{getFilter()}</div>
          <br/>
          {getTableByFilter()}
        </div>
      </div>
    </section>
  );
}
export default Documents;

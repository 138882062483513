import React, { useEffect } from 'react';
import { DocumentRef } from "../../graphql/API";
import { Storage } from 'aws-amplify';

function ImageLoader(props:{document:DocumentRef}) {
  console.log('show',props.document)
  const [fileUrl, setFileUrl] = React.useState('')

  useEffect(() => {
    if(
      props.document.mimeType === "image/svg+xml"
      ||
      props.document.mimeType === "application/pdf"
      )
    {
      Storage.get(props.document.s3Key).then((response) => {
        setFileUrl(response.toString())
      })
    }
    else{
      Storage.get(props.document.s3Key.replace('documents/','thumbnails/300/')).then((response) => {
        // es wird davon ausgegangen das ein thumbnail vorliegt!
        setFileUrl(response.toString())
      })
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  if (props.document.mimeType === "application/pdf") {
    return (
      <iframe
        title={props.document.originalFileName}
        src={fileUrl}
        style={{ height: "400px" }}
      ></iframe>
    );
  } else if (props.document.mimeType === "image/svg+xml") {
    return (
      <div style={{ width: "300px" }}>
        <img src={fileUrl} alt={props.document.originalFileName} />
      </div>
    );
  }  else{
    return ( 
      <div style={{ maxHeight: "300px",maxWidth: "300px" }}>
        <img src={fileUrl} alt={props.document.originalFileName} />
      </div>
      )
    } 
  }
  
export default ImageLoader;
  
import Tile from './index'
import icon from './../../assets/images/icons/docs.svg'
import Button from './../Button'
import { useHistory } from 'react-router-dom';

function TileLocal() {
    let history = useHistory();
    var headline = 'Meine Dokumente'
    var content = <div className=" text-lg pl-6 pr-6 flex flex-col">
        <div className=" fixHeightIfOneButtonIsAtTheBottom">
          <div className="pb-4"><Button onClick={ ()=>  history.push('/documents/contracts')  } label="Verträge"/></div>
          <div className="pb-4"><Button onClick={ ()=>  history.push('/documents/bills')  } label="Rechnungen"/></div>
        </div>
        <div className="pt-0"><Button onClick={ ()=>  history.push('/documents/')  } label="Alle Dokumente"/></div>
      </div>

    return (
        <Tile icon={icon} headline={headline} content={content} />
    );
  }
  
export default TileLocal;
  
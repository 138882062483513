import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useGlobalState, GlobalStateInterface } from "../utils/globlStateProvider";
import { I18n } from '@aws-amplify/core';
import { strings as stringHeader } from './HeaderLocalization';
import { strings as stringGlobal } from './../utils/globalLocalization';
import { USER_CHOICE_LANGUAGE } from './../utils/constants';
import { handleLogout } from '../utils/helpers';
import { mergeDeepRight } from 'ramda';
import './HeaderMenu.css';
import { CloseOutlined } from '@ant-design/icons';
import IconFolderOpenerWhite from '../assets/images/icon_folderOpener_white.svg'
import arrowRightWhite from '../assets/images/icons/arrow_right_white.svg'

I18n.putVocabularies(mergeDeepRight(stringHeader,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Menu(props:{show?:boolean,onClick: () => void }) {
    // eslint-disable-next-line 
  const { state, setState } = useGlobalState();
  const [userName, setUsername] = useState('')

  useEffect(() => {
        if(state.username){
      setUsername(state.username)
    }
  }, [ state]);  

  const handleLocalisation = (value:string) => {
    localStorage.setItem(USER_CHOICE_LANGUAGE, value);
    I18n.setLanguage(value)

    let dataGlobal:Partial<GlobalStateInterface> = {language:value}
    setState((prev) => ({ ...prev, ...dataGlobal }));
  }

  let choosenLanguage = 'Deutsch'
  if(localStorage.getItem(USER_CHOICE_LANGUAGE) === 'en'){
    choosenLanguage = 'English'
  }

  return (
    <div id="menu" className="text-white m-0">
      <div className="flex justify-between border-b border-greyLighter border-opacity-25">
        <div className="mr-10 isFolderOpener p-7">
          <div className="flex hidden">{choosenLanguage}<img className=" w-4 ml-3" src={IconFolderOpenerWhite} alt="" /></div>
          <div className="subnav language hidden">
              <div className="pl-5 pr-5 pt-0 pb-0 border-t-2 first:border-t-0"><span className="block link" onClick={() => handleLocalisation('en')}>English</span></div>
              <div className="pl-5 pr-5 pt-0 pb-0 border-t-2 first:border-t-0"><span className="block link" onClick={() => handleLocalisation('de')}>Deutsch</span></div>
          </div>
        </div>
        <div className=" p-7"><CloseOutlined style={{fontSize:26}} onClick={()=> props.onClick()} /></div>
      </div>
      <ul className="first">
        <li className="p-7 border-b border-white text-2xl"><Link to={'/'} className="flex justify-between">Dashboard<img src={arrowRightWhite} alt="" className="arrow link w-3 text-white" /></Link></li>
        <li className="p-7 border-b border-white text-2xl"><Link to={'/installations'} className="flex justify-between">Anlagen<img src={arrowRightWhite} alt="" className="arrow link w-3 text-white"/></Link></li>
        <li className="p-7 border-b border-white text-2xl"><Link to={'/documents'} className="flex justify-between">Dokumente<img src={arrowRightWhite} alt="" className="arrow link w-3 text-white"/></Link></li>
      </ul>
      <ul className="second">
        <li className="p-7 pt-7 pb-3 text-xl flex justify-between link  border-b border-white " onClick={()=>handleLogout()}>
          <div>
            <div className=" text-blueDark text-sm pb-2">Angemeldet als:<br/> {userName}</div>
            {I18n.get('logout')}
          </div>
          <img src={arrowRightWhite} alt="" className="arrow link w-2 text-white"/>
        </li>
        <li className="p-7 pt-3 pb-3 text-xl flex justify-between hidden" >Mein Konto<img src={arrowRightWhite} alt="" className="arrow link w-2 text-white"/></li>
        <li className="p-7 pt-3 pb-7 border-b border-white text-xl flex justify-between hidden" >Live-Chat<img src={arrowRightWhite} alt="" className="arrow link w-2 text-white"/></li>
      </ul>
      <div className="p-7 text-xl meta">
        +49 (0) 30 629 321 38<br/><br/>
        info@aufzughelden.com
      </div>
    </div>
  );
}

export default Menu;

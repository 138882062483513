
import { AUTH_IOT_USER } from "./constants";
import awsIot, { device } from 'aws-iot-device-sdk';
import axios from 'axios'
import {get_api_endpoint_iot, get_api_endpoint_iot_management} from './helpers'
import moment from "moment";

export const initLiveFeed = (deviceId:string, setLiveFeedActivated:(arg0: any) => void, setMqttClient:(arg0: device) => void , setlastFiredliveMessages:(arg0: any) => void) => {
   
    var options = {
      region:"eu-central-1",
      accessKeyId:getAuthIotUser('accessKeyId'),
      secretKey:getAuthIotUser('secretKey'),
      sessionToken:getAuthIotUser('sessionToken'),
      clientId:getAuthIotUser('clientId')+'-'+getNewInstanceId(),
      host:getAuthIotUser('iotEndpoint'),
      protocol:"wss" as const,
      port:443,
      debug:false
    }
  
    var client =  new awsIot.device(options);
    setMqttClient(client)

    client
    .on('connect', function() {
      client.subscribe('identities/'+getAuthIotUser('clientId')+'/from_platform');  
      initiateMqqtMessagesByApi(deviceId,setLiveFeedActivated )
    });
  
    client.on('close', function() {
      console.log('disconnected', arguments);
    });
  
    client.on('error', function() {
        console.log('error', arguments);
    });
  
    client.on('reconnect', function() {
        console.log('reconnecting', arguments);
    });
  
    client.on('message', function(topic, payload) {
        setlastFiredliveMessages(JSON.parse(payload.toString()))
      });
  }

export function initiateMqqtMessagesByApi(deviceId:string,setLiveFeedActivated:(arg0: any) => void){
    const postBody = {
        durationInMinutes: 30
    };
    
    axios.create(
        {
            headers: {
                'authorization': getAuthIotUser('authenticationToken')
            }
        }
    )
    .post( get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/live_feed', postBody)
    .then((response) => {
        setLiveFeedActivated(true)
    });    

  }

  export function getAuthIotUser(param: string | null = null ){
    let localStorageString = localStorage.getItem(AUTH_IOT_USER)
    if(localStorageString){
      let localStorageObject = JSON.parse(localStorageString)
      if(param){
        return localStorageObject[param]
      }
      else{
        return localStorageObject
      }
    }
    else{
      return null
    }
  }

  function getNewInstanceId() {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5));
  }

 export const loadDevice = (deviceId:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
    setterLoading(true)
    axios.get( get_api_endpoint_iot_management() +'/devices/'+deviceId,
    {headers: {
      'authorization': getAuthIotUser('authenticationToken')
    }})
    .then((response) => {
        setterLoading(false)
        setterResponse({...response.data.device})
    });
  }

export const loadRunsBetweenAllFloors = (deviceId:string, from:string, to:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
    setterLoading(true)
    axios.get( get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/runs_between_all_floors?startDate='+from+'&endDate='+to,
    {headers: {
      'authorization': getAuthIotUser('authenticationToken')
    }})
    .then((response) => {
        setterLoading(false)
        setterResponse({...response.data.data})
    });
  }

  export const loadRunsPerFloor = (deviceId:string, from:string, to:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
    setterLoading(true)

    axios.get( get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/runs_per_floor?startDate='+from+'&endDate='+to,
    {headers: {
      'authorization': getAuthIotUser('authenticationToken')
    }})
    .then((response) => {
        setterLoading(false)
        setterResponse({...response.data.data})
    });
  }

  export const loadDoorCyclesCount = (deviceId:string, from:string, to:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
    setterLoading(true)

    axios.get( get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/door_cycles_count?startDate='+from+'&endDate='+to,
    {headers: {
      'authorization': getAuthIotUser('authenticationToken')
    }})
    .then((response) => {
        setterLoading(false)
        setterResponse({...response.data.data})
    });
  }  

export const loadPastEvents = (deviceId:string, from:string, to:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
  setterLoading(true)
  axios.get( get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/events?startDate='+from+'&endDate='+to+'&maxSize=500',
  {headers: {
    'authorization': getAuthIotUser('authenticationToken')
  }})
  .then((response) => {
      setterLoading(false)
      setterResponse({...response.data})
  });
}

export const loadRunsOverTime = (deviceId:string, from:string, to:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
    setterLoading(true)

    let interval  = (Math.abs(moment(from).diff(moment(to), 'days') ) <= 14) ? 'hour' : 'day'  

    axios.get(get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/runs_over_time?startDate='+from+'&endDate='+to+'&interval='+interval,
    {headers: {
      'authorization': getAuthIotUser('authenticationToken')
    }})
    .then((response) => {
        setterLoading(false)
        setterResponse({...response.data.data})
    });
}

export const loadDurations = (deviceId:string, from:string, to:string, setterLoading: (arg0: boolean) => void,setterResponse: (arg0: any) => void) => {
    setterLoading(true)
    axios.get( get_api_endpoint_iot() + '/devices/'+deviceId+'/activity/durations?startDate='+from+'&endDate='+to,
    {headers: {
      'authorization': getAuthIotUser('authenticationToken')
    }})
    .then((response) => {
        setterLoading(false)
        setterResponse({...response.data.data})
    });
}

export function isDoorActivityAvailable(data:any){

    // if minimum 2 values are given than show the item
    let foundValuesCount = 0
    if(data.door_closings_duration && data.door_closings_duration > 0) { foundValuesCount ++ }
    if(data.door_openings_duration && data.door_openings_duration > 0) { foundValuesCount ++ }
    if(data.door_opened_duration && data.door_opened_duration > 0) { foundValuesCount ++ }
    // if minimum 2 values are given than show the item
    if(foundValuesCount >= 2){ return true }
    else{ return false}
  }

export const getHumanReadableFloorLabel = (floor:number|boolean,lobbyFloor:number) => {
 
  let floorLabel = ''
    if( lobbyFloor){
      floor = parseInt(floor.toString())
      if(floor === lobbyFloor){
        floorLabel = "E"
      }
      else if(floor > lobbyFloor){
        floorLabel = (floor-lobbyFloor).toString()
      }
      else if(floor < lobbyFloor){
          let deepFloors = Array.from(Array(lobbyFloor).keys()).reverse()
          floorLabel = ((deepFloors[floor]+1)*-1).toString()
      }
    }

    return floorLabel
}

export const isValidDoorConfiguration = (doorConfiguration:any[]) => {
  let acceptableDoorOrientations = ['North','South','West','East']
  let listOfPositionFound:string[] = []
  let configurationIsValid = true
  
  doorConfiguration.forEach(item => {
    if(listOfPositionFound.includes(item)){
      // found the same direction
      configurationIsValid = false
    }
    else{
      if(acceptableDoorOrientations.includes(item)){
        listOfPositionFound.push(item)
      }
      else{
        // String not lie it should be
        configurationIsValid = false
      }
    }
  })
  return configurationIsValid
}

const awsmobileProduction = {
  "env":"prod",
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": "eu-central-1:dd7ac63b-c10c-4689-aa5d-d58385feb773",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_sa8w5ofaU",
  "aws_user_pools_web_client_id": "38agh8vcbnh1dv78ga05vmosc1",
  "oauth": {},
  "aws_user_files_s3_bucket": "ahplatform9a16ac95bb9a4a548fbcd5eb1f732b2e180801-main",
  "aws_user_files_s3_bucket_region": "eu-central-1",
  "aws_dynamodb_all_tables_region": "eu-central-1",
  "aws_dynamodb_table_schemas": [
      {
          "tableName": "single-main",
          "region": "eu-central-1"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://dgi2qvspljbhxkg33zhhgmilqm.appsync-api.eu-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_api_endpoint":"https://eeb4cqgg3e.execute-api.eu-central-1.amazonaws.com/main",
  "aws_api_endpoint_iot":"https://metrics-activity-service.prod.connectedelevation.com",
  "aws_api_endpoint_iot_management":"https://device-management-service.prod.connectedelevation.com"
};

const awsmobileTest = {
  "env":"test",
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": "eu-central-1:1cabe01e-7fc6-46d2-9951-de9d8b9f812c",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_BLdKyGXZi",
  "aws_user_pools_web_client_id": "6ikb7mmafeau73itt7ptod6gna",
  "oauth": {},
  "aws_user_files_s3_bucket": "ahplatform9a16ac95bb9a4a548fbcd5eb1f732b2e104908-test",
  "aws_user_files_s3_bucket_region": "eu-central-1",
  "aws_dynamodb_all_tables_region": "eu-central-1",
  "aws_dynamodb_table_schemas": [
      {
          "tableName": "single-test",
          "region": "eu-central-1"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://arblxugswvdijeip6xkjdip5gm.appsync-api.eu-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_api_endpoint":"https://ldctebhwhf.execute-api.eu-central-1.amazonaws.com/test",
  "aws_api_endpoint_iot":"https://metrics-activity-service.dev.connectedelevation.com",
  "aws_api_endpoint_iot_management":"https://device-management-service.dev.connectedelevation.com"
};

const awsmobileDevelopment = {
  "env":"dev",
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": "eu-central-1:9b4b2d61-45de-4bcc-ad2c-6fa8815320f5",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_oMoMectjG",
  "aws_user_pools_web_client_id": "1nijrnbrp6f1o739b6i9hdrrjs",
  "oauth": {},
  "aws_user_files_s3_bucket": "ahplatform9a16ac95bb9a4a548fbcd5eb1f732b2e195027-dev",
  "aws_user_files_s3_bucket_region": "eu-central-1",
  "aws_dynamodb_all_tables_region": "eu-central-1",
  "aws_dynamodb_table_schemas": [
      {
          "tableName": "single-dev",
          "region": "eu-central-1"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://6znelhmyrba4vbjrvquibcppzm.appsync-api.eu-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_api_endpoint":"https://vgsup1hkyl.execute-api.eu-central-1.amazonaws.com/dev",
  "aws_api_endpoint_iot":"https://metrics-activity-service.dev.connectedelevation.com",
  "aws_api_endpoint_iot_management":"https://device-management-service.dev.connectedelevation.com"
};

var awsconfig = {}
if(process.env.REACT_APP_ENVIRONMENT === "production"){
  awsconfig = awsmobileProduction
}
else if(process.env.REACT_APP_ENVIRONMENT === "test"){
  awsconfig = awsmobileTest
}
else{
  awsconfig = awsmobileDevelopment
}

// local override
//awsconfig = awsmobileProduction

export default awsconfig;
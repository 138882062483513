import { strings as stringGlobal } from './../utils/globalLocalization';
import { strings as stringFooter } from './FooterLocalization';
import { I18n } from '@aws-amplify/core';
import { USER_CHOICE_LANGUAGE } from './../utils/constants';
import { mergeDeepRight } from 'ramda';
import './Footer.css'

I18n.putVocabularies(mergeDeepRight(stringFooter,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Footer() {
  return (
    <section className="Footer mt-20">
      <div>
        <div className="topElem flex w-full">
          <div className="flex flex-grow self-end pb-3">
            <a href="https://www.facebook.com/Aufzughelden" target="_blank" rel="noreferrer">
              <svg viewBox="0 0 15 24" version="1.1" className="h-6 pr-4">
                <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
                    <g id="Follow-us" transform="translate(-154.000000, -209.000000)" >
                        <g transform="translate(140.000000, 199.000000)">
                            <g id="Facebook" transform="translate(-0.000000, 0.000000)">
                                <path d="M18.534395,34 L18.5,23.5 L14,23.5 L14,19 L18.5,19 L18.5,16 C18.5,11.9512 21.007265,10 24.61907,10 C26.349155,10 27.83609,10.128805 28.26941,10.186375 L28.26941,14.417605 L25.76444,14.418745 C23.800145,14.418745 23.419805,15.35215 23.419805,16.72186 L23.419805,19 L29,19 L27.5,23.5 L23.41979,23.5 L23.41979,34 L18.534395,34 L18.534395,34 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
              </svg>
            </a>
            <a href="https://www.linkedin.com/showcase/aufzughelden/" target="_blank" rel="noreferrer">
              <svg viewBox="0 0 24 24" version="1.1" className="h-6">
                <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
                    <g id="Follow-us" transform="translate(-212.000000, -208.000000)" >
                        <g transform="translate(140.000000, 199.000000)">
                            <g id="Group" transform="translate(62.000000, 0.000000)" fillRule="nonzero">
                                <g id="linkedin" transform="translate(-0.000000, 0.000000)">
                                    <path d="M34,33 L29.0323144,33 L29.0323144,25.2050385 C29.0323144,23.3449965 28.9945852,20.9538139 26.4457642,20.9538139 C23.8571179,20.9538139 23.4609607,22.9776067 23.4609607,25.0685794 L23.4609607,33 L18.4932751,33 L18.4932751,16.9713086 L23.2639301,16.9713086 L23.2639301,19.1546536 L23.3268122,19.1546536 C23.9933624,17.8950315 25.6157205,16.5661302 28.0366812,16.5661302 C33.0672489,16.5661302 34,19.8831351 34,24.2015395 L34,32.9937019 L34,33 Z M12.8841921,14.7774668 C11.2869869,14.7774668 10,13.4821554 10,11.886634 C10,10.2953114 11.2869869,9 12.8841921,9 C14.4772052,9 15.7683843,10.2953114 15.7683843,11.886634 C15.7683843,13.4821554 14.4751092,14.7774668 12.8841921,14.7774668 Z M15.3743231,33 L10.3940611,33 L10.3940611,16.9713086 L15.3743231,16.9713086 L15.3743231,33 L15.3743231,33 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
              </svg>
            </a>
          </div>
          <div className=" text-base self-end">+49 (0) 30 629 321 38<br/>
            info@aufzughelden.com</div>
        </div>
        <div className="grid grid-cols-12 pt-2">
          <div className="bottomElem col-span-12 pt-4 flex flex-col-reverse l:block">
            <div className="l:float-left s2 ">{I18n.get('footerText')}</div>
            <div className=" pb-4 l:pb-0 l:float-right s2">
              <a className=" pr-8 l:pr-0 l:pl-8" href={I18n.get('imprintLink')} target="_blank" rel="noreferrer">{I18n.get('imprint')}</a>
              <a className=" pr-8 l:pr-0 l:pl-8" href={I18n.get('dataProtectionLink')} target="_blank" rel="noreferrer">{I18n.get('dataProtection')}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;

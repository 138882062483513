
export const strings = {
    en: {
      imprint: 'Imprint',
      imprintLink: 'https://www.aufzughelden.com/de/impressum',
      dataProtection: 'Data protection',
      dataProtectionLink: 'https://www.aufzughelden.com/de/datenschutz',
      footerText: '© Aufzughelden 2022. All rights reserved',
    },
    de: {
      imprint: 'Impressum',
      imprintLink: 'https://www.aufzughelden.com/de/impressum',
      dataProtection: 'Datenschutz',
      dataProtectionLink: 'https://www.aufzughelden.com/de/datenschutz',
      footerText: '© Aufzughelden 2022. Alle Rechte vorbehalten',
    },
  };
import React from "react";
import './Tile.css'
import arrowLong from '../../assets/images/icons/arrow_long.svg'
import arrowOpener from '../../assets/images/icons/arrow_down.svg'
import arrowRight from '../../assets/images/icons/arrow_right.svg'

export enum stylesTile {
  darkBlue = 'bg-blueDark',
  lightBlue = 'bg-blueLight',
  warnRed = 'bg-warnRed'
}

function Tile(props:{icon:any, headline:string, content:any , className?:string, isSmall?:boolean, isOpener?:boolean , innerLink?:string , style?:stylesTile}){

  let arrClasses = ['text-white','tile']
  props.isSmall ? arrClasses.push('small') : void(0) ;
  props.style ? arrClasses.push(props.style) : arrClasses.push('bg-blueDark')

  return (
    <div className={arrClasses.join(' ') + ' ' + props.className} >
        {props.isSmall?
        <HeadSmall icon={props.icon} headline={props.headline} isOpener={props.isOpener}  />
        :
        <Head icon={props.icon} headline={props.headline} innerLink={props.innerLink}  />
        }
        <div>{props.content}</div>
    </div>
  );
}

const getIcon = (icon:any) => {
  if(typeof icon === 'object'){
    return icon
  }
  else{
    return <img src={icon} alt="" className="icon" />
  }
}

function Head(props:{icon:any, headline:string, innerLink?:string }){
  //console.log(typeof props.icon)
    return <div className="flex p-6">
            <div className="w-13">{getIcon(props.icon)}</div>
            <h3 className="text-white text-xl">{props.headline}</h3>
            {
              props.innerLink?
              <img src={arrowRight} alt="" className="arrow link ml-auto" />
              :
              <></>
            }
        </div>
}

function HeadSmall(props:{icon:any, headline:string , isOpener?:boolean}){
    return <div className="flex p-0 items-center h-full pl-6 small">
            {getIcon(props.icon)}
            <div className="text-white text-base">{props.headline}</div>
            <div className=" ml-auto pr-6">
              {props.isOpener?
              <img src={arrowOpener} alt="" className="arrow opener" />
              :
              <img src={arrowLong} alt="" className="arrow" />
              }
              </div>
        </div>
}

export default Tile;

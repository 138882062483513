import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import arrowIotDown from '../../../assets/images/icons/arrow_down_iot.svg'
import arrowIotUp from '../../../assets/images/icons/arrow_up_iot.svg'
import { getHumanReadableFloorLabel } from '../../../utils/helperIoT';

function LevelVisualization(props:{initialData:any,liveMessages:any,isLoading:boolean,device:any}) {

    enum DirectionStatus {
        UP = "up",
        DOWN = "down",
        UNSET = "unset",
      }

    const [currentFloor, setCurrentFloor] = useState<number|boolean>(false)
    const [goalFloors , setGoalFloors] = useState<any[]>([])
    const [direction , setDirection] = useState<DirectionStatus>(DirectionStatus.UNSET)

    useEffect(() => {
        //console.log(props.liveMessages)

        if(props.liveMessages?.length > 0){

            // detecting direction
            if(props.liveMessages[0].body?.name === "deviceDirectionChanged" ){
                if(props.liveMessages[0].body.attributes.direction === "directionUp"){
                    setDirection(DirectionStatus.UP)
                }
                else if(props.liveMessages[0].body.attributes.direction === "directionDown"){
                    setDirection(DirectionStatus.DOWN)
                }
                else{
                    setDirection(DirectionStatus.UNSET)
                }
            }

            // detecting arrived floor
            if(props.liveMessages[0].body?.name === "deviceMoved" ){
                setCurrentFloor(props.liveMessages[0].body.attributes.floor)
            }

            // detecting goal floor
            if(props.liveMessages[0].body?.name === "deviceGoalFloorsChanged" ){
                setGoalFloors([...props.liveMessages[0].body.attributes.goalFloors])
            }
        } 
    }, [props.liveMessages]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // detecting current floor
        if(props.initialData.activityEvents?.length > 0){
            for (const message of props.initialData.activityEvents){
                if(message.eventName === "deviceDoorCycle" && parseInt(message.floor) >= 0){
                    setCurrentFloor(message.floor)
                    break;
                }
            }
        }
      }, [props.initialData]);// eslint-disable-line react-hooks/exhaustive-deps

    function getNextFloors(){
        let nextFloors = []
        let label = goalFloors.length > 1 ? 'Nächste Halte':'Nächster Halt'

        for (const floor of goalFloors){
            nextFloors.push(<div  key={floor} className=" border border-blueDark text-4xl w-20 h-20 flex justify-center items-center mr-6"><div>{  getHumanReadableFloorLabel(floor,parseInt(props.device.lobbyFloor)) }</div></div>)
        }
        return ( 
            <div className=" mr-6">
                <div className="mb-4 text-base h-6">{label}</div>
                <div className="flex mr-6">
                    {nextFloors}
                </div>
            </div>
        )
    }
//console.log('currentFloor',currentFloor)
    return (
        <Spin size="large" spinning={props.isLoading}>
            <div className="text-base font-bold mb-4">Live-Tracking Aufzug</div>
            <div className="flex">
                <div className="mr-6">
                <div className="mb-4">Aktueller Halt</div>
                {currentFloor >= 0 && 
                    <div className="flex">
                        <div className=" bg-blueLight border border-blueDark text-white text-4xl w-20 h-20 flex justify-center items-center"><div>{  getHumanReadableFloorLabel(currentFloor,parseInt(props.device.lobbyFloor)) }</div></div>
                        {direction === DirectionStatus.UP && <img className=" w-4 ml-6" src={arrowIotUp} alt="" /> }
                        {direction === DirectionStatus.DOWN && <img className=" w-4 ml-6" src={arrowIotDown} alt="" /> }
                        {direction === DirectionStatus.UNSET && <div className=" w-4 ml-6" /> }
                    </div>
                }
                </div>
                {goalFloors?.length > 0 && getNextFloors() }
            </div>
        </Spin>
    );
}
export default LevelVisualization;

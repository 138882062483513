import './Dashboard.css';
import { Link } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { strings as stringLocal} from './DashboardLocalization';
import { strings as stringGlobal } from './../utils/globalLocalization';
import { USER_CHOICE_LANGUAGE } from './../utils/constants';
import { mergeDeepRight } from 'ramda';
import TileInstallations from './Tile/TileInstallations'
import TileNewServiceTask from './Tile/TileNewServiceTask'
import TileIndividualise from './Tile/TileIndividualise'
import TileDocuments from './Tile/TileDocuments'
import TileMessages from './Tile/TileMessages'
import TileFeedback from './Tile/TileFeedback'
import TileMap from './Tile/TileMap'
import { GlobalStateInterface, useGlobalState } from "../utils/globlStateProvider";
import { useEffect } from 'react';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
//import { promiseLoadContracts } from '../utils/helpers';
import { API, GraphQLResult } from '@aws-amplify/api'
import { Query, SmartMaintenanceContractPage } from '../graphql/API';
import * as queries from './../graphql/queries';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Dashboard() {
  const { state, setState } = useGlobalState();

  useEffect(() => {
    document.body.classList.add('isDashboard');

    return () => {
      document.body.classList.remove('isDashboard');
    };
  }, [ ]);


    const loadContracts = async() => {
      const res = await (API.graphql({ query: queries.contracts , variables:{ page:{}, filter:{} }}) as GraphQLResult<Query> );
      if(res.data?.contracts?.items != null){
        let dataGlobal:Partial<GlobalStateInterface> = {
          contracts: res.data?.contracts as SmartMaintenanceContractPage,
          contractsAreLoaded:true
        } 
        setState((prev) => ({ ...prev, ...dataGlobal }));
      }
    }
    if(!state.contractsAreLoaded){ loadContracts() }
  /*
  if(!state.contractsAreLoaded && !state.contracts?.before){ 
    promiseLoadContracts.then( ( dataGlobal ) => {
      if(dataGlobal && typeof dataGlobal === 'object'){
        setState((prev) => ({ ...prev, ...dataGlobal }));
      }
    })
  }*/

  const popOvercontent = (
    <div>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    </div>
  );

  return (
    <section className="Content">
      <div>
        <h3 className="pb-9 flex place-items-start">
          {I18n.get('titleDashbaord')}
          <Popover 
            placement="bottom"
            content={popOvercontent}
            title="Dashboard"
            trigger="click"
            className="pl-2 hidden"
            overlayClassName="headlinePopover"
            >
            <InfoCircleOutlined style={{ fontSize:16}}/>
          </Popover>
        </h3>
        <div className="grid grid-cols-12 gridWithoutMB">
          <div className="col-span-12 m:col-span-6 xl:col-span-4 hidden"><TileNewServiceTask isSmall={true} /></div>
          <div className="col-span-4 hidden"><TileIndividualise isSmall={true} /></div>
        </div>
        <div className="grid grid-cols-12 mt-7">
          <div className="col-span-12 m:col-span-6 xl:col-span-4"><Link to={'/installations/'}><TileInstallations className="TileInstallations"/></Link></div>
          <div className="col-span-12 m:col-span-6 xl:col-span-4"><TileMap /></div>
          <div className="col-span-12 m:col-span-6 xl:col-span-4"><TileMessages /></div>
          <div className="col-span-12 m:col-span-6 xl:col-span-4"><TileDocuments /></div>
          <div className="col-span-12 m:col-span-6 xl:col-span-4 hidden"><TileFeedback /></div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;

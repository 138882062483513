import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginContainer from '../components/Login/LoginContainer';
import LogoutContainer from '../components/Login/LogoutContainer';
import ForgotPasswordContainer from '../components/Login/ForgotPasswordContainer';
import ForgotPasswordCodeContainer from '../components/Login/ForgotPasswordCodeContainer';
import Dashboard from "../components/Dashboard"
import Installations from "../components/Installations"
import Documents from "../components/Documents"
import InstallationDetail from "../components/InstallationDetail"

import PrivateRoute from './PrivateRoute';
import { GlobalStateProvider } from "../utils/globlStateProvider";
import { Auth } from "aws-amplify";
//import { notification } from "antd";
import { AUTH_USER_TOKEN_KEY,AUTH_IOT_USER } from "../utils/constants";
import axios from 'axios'
import { get_api_endpoint } from '../utils/helpers';

function App() {
  const  CheckSession = async () => {
    try {
      if(window.location.pathname !== "/logout"){
        if(localStorage.getItem(AUTH_USER_TOKEN_KEY)){

          Auth.currentSession()
          .then(data => {
            let newToken = data.getAccessToken().getJwtToken()
            // update storage token because its needed for private routes
            localStorage.setItem(AUTH_USER_TOKEN_KEY, newToken);      
            // refresh iot token
            axios.get(get_api_endpoint() + '/iot/auth',  {headers: {
              'authorization': newToken
              }})
              .then((response) => { 
                localStorage.setItem(AUTH_IOT_USER, JSON.stringify(response.data) );
              })
              .catch(err => console.log('token2', err));;
          })
          .catch(err => {
            if(window.location.pathname !== "/logout" && window.location.pathname !== "/login"){
              window.location.href = "/logout"; // /logout page cleares the local storage
            }
          });
        }
        else{
          if(window.location.pathname !== "/login"){
            window.location.href = "/login";
          }        
        }
      }

    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }

  useEffect(
    () => {
      CheckSession()
      const intervallID = window.setInterval(() => {
        CheckSession()
        }, 60000*25);// 25 minuten

      return () => {
        window.clearInterval(intervallID )
      };
    },
    []
  );

  return (
    <Router>
      <GlobalStateProvider>
        <PrivateRoute exact={true} path="/" component={Dashboard} />
        <PrivateRoute exact={true} path="/installations" component={Installations} />
        <PrivateRoute exact={true} path="/documents" component={Documents} />
        <PrivateRoute exact={true} path="/documents/contracts" component={Documents} />
        <PrivateRoute exact={true} path="/documents/bills" component={Documents} />
        <PrivateRoute exact={true} path="/installations/:id" component={InstallationDetail} />
        <Route exact={true} path="/login" component={LoginContainer} />
        <Route exact={true} path="/logout" component={LogoutContainer} />
        <Route exact={true} path="/forgot-password" component={ForgotPasswordContainer} />
        <Route exact={true} path="/password-reset" component={ForgotPasswordCodeContainer} />
      </GlobalStateProvider>
    </Router>
  );
  
}

export default App;
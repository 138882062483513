import passwordValidator from 'password-validator'
import { I18n } from '@aws-amplify/core';

const formatPasswordValidateError = (errors: Array<string>) => {
    for (let i = 0; i < errors.length; i++) {
        if (errors[i] === 'min') {
            return I18n.get('validationPwdMin');
        } else if (errors[i] === 'lowercase') {
            return I18n.get('validationPwdLowercase');
        } else if (errors[i] === 'uppercase') {
            return I18n.get('validationPwdUppercase');
        } else if (errors[i] === 'digits') {
            return I18n.get('validationPwdDigits');
        } else if (errors[i] === 'symbols') {
            return I18n.get('validationPwdSymbols');
        }
    }
};


export const validateNextPassword =  async (rule: object, value: string, callback: (message?: string) => void) => {

    var schema = new passwordValidator();
    schema
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(1)                                // Must have at least 2 digits
    .has().not().spaces()                           // Should not have spaces
    .has().symbols()                                // Should contain specialchars
    //.is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values
    var validationRulesErrors = schema.validate(value, { list: true })

    if (validationRulesErrors.length > 0) {
        return Promise.reject(new Error(formatPasswordValidateError(validationRulesErrors)));
    }
};

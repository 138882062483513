import { Spin } from 'antd';
import './Status.css';
import { useEffect, useState } from 'react';

function TurnsChart(props:{device:any,isLoading:boolean}) {

  const [status , setStatus] = useState<any[]>([])

  useEffect(() => {
    setStatus([getStatus({})])
  }, []);

  useEffect(() => {
    setStatus([getStatus(props.device)])
  }, [props.device]);

  const getStatus = (device: any) =>{

    let status = <div className="flex w-full">
      <div className="w-1/2">Status</div>
      <div className="w-1/2"></div>
    </div>

    if( parseInt(device.ragMqtt) === 0){
      status = <div className="flex w-full">
        <div className="w-1/2">Status</div>
        <div className="w-1/2 text-green">OK</div>
      </div>
    }
    else if(parseInt(device.ragMqtt) === 1){
      status = <div className="flex w-full">
        <div className="w-1/2">Status</div>
        <div className="w-1/2 text-warnOrange">Datenanbindung verloren</div>
      </div>
    }
   
    return [status]
  }

  return (
    <Spin size="large" spinning={props.isLoading} wrapperClassName="StatusComponent">
      {status}
    </Spin>
  );
}
export default TurnsChart;

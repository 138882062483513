import Tile from './index'
import {stylesTile} from './index'
import icon from './../../assets/images/icons/feedback.svg'
import Button from './../Button'
import {stylesButton} from './../Button'

function TileLocal() {
    var headline = 'Feedback'
    var content = <div className=" text-lg pl-6 pr-6 flex flex-col">
        <div className="pb-4 pl-11 fixHeightIfOneButtonIsAtTheBottom">Helfen Sie uns,  unseren Service für Sie fortlaufend zu optimieren und geben uns Ihr Feedback!</div>
        <div className="pt-0"><Button linkToExtern='https://www.surveymonkey.de/r/H8CGHTJ' label="Zum Feedback Formular" styles={stylesButton.darkBlue}/></div>
      </div>

    return (
        <Tile icon={icon} headline={headline} content={content} style={stylesTile.lightBlue} />
    );
  }
  
export default TileLocal;
  

export const strings = {
    en: {
      navDashboard: 'Dashboard',
      navInstallations: 'Installations',
      navDocuments: 'Documents',
    },
    de: {
      navDashboard: 'Dashboard',
      navInstallations: 'Anlagen',
      navDocuments: 'Dokumente',
    },
  };
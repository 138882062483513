
export const strings = {
    en: {
        logout: 'Logout',
        notificationSuccess: 'Successful!',
        notificationError: 'Error!'
    },
    de: {
        logout: 'Abmelden',
        notificationSuccess: 'Erfolgreich!',
        notificationError: 'Fehler!'
    },
  };
import Tile from './index'
import icon from './../../assets/images/icons/dashboard.svg'

function TileLocal(props:{isSmall?:boolean}) {
    var headline = 'Dashboard individualiseren'
    return (
        <Tile icon={icon} headline={headline} content={null} isSmall={props.isSmall} isOpener={true} />
    );
  }
  
export default TileLocal;
  
import { Route, Redirect } from 'react-router-dom';
import Header from './../components/Header'
import Footer from './../components/Footer'
import { useGlobalState, GlobalStateInterface } from "../utils/globlStateProvider";
import { Auth } from 'aws-amplify';
import { validateToken,handleLogout } from '../utils/helpers';
import { useEffect } from 'react';
import { useState } from "react";
const PrivateRoute = ({ component: Component, ...rest }: any & { component: any }) => {
  const {state,setState} = useGlobalState()
  const isUserValide = validateToken()
  const [userObj, setUserObj] = useState({userEmail:'',userName:''})

  useEffect(() => {
    let data:Partial<GlobalStateInterface> = {username:userObj.userEmail,name:userObj.userName,loadAndReloadInitDone:true}  
    setState((prev) => ({ ...prev, ...data }));
  }, [userObj]);// eslint-disable-line react-hooks/exhaustive-deps

  if(isUserValide && !state.loadAndReloadInitDone){
    Auth.currentAuthenticatedUser().then(
      user => {
        setUserObj({userEmail:user.attributes.email,userName:user.attributes.name})
      }
    )
    .catch(err => {
      console.log(err)
      handleLogout()
    });
  }

  return (
    <Route
      {...rest}
      render={props => {        
        return isUserValide ? (
          <>
            <Header />
              <Component {...props} />
            <Footer />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
import Tile from './index'
import icon from './../../assets/images/icons/ring.svg'
import { Badge } from 'antd';
import { useGlobalState } from '../../utils/globlStateProvider';
import { MaintenanceContractStatus } from '../../graphql/API';
import { useEffect } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import * as uuid from 'uuid';

function TileLocal() {
    const { state } = useGlobalState();
    const [messages, setMessages] = React.useState<JSX.Element[]>([])
    const [messagesCount, setMessagesCount] = React.useState(0)
    var headline = 'Meldungen'

    const numberOfUnsignedContracts = () => {
      let counter = 0
      state.contracts?.items?.forEach(contract => {
        if( 
          contract?.status 
          && contract?.status === 'ReadyToAccept'){
            counter++
        }
      });
      return counter
    }

    useEffect(() => {
      let arrMessages: JSX.Element[] = []
      let arrValidContractStates: MaintenanceContractStatus[] = ['ReadyToAccept','Accepted']
      let readyToAcceptCount = 0
      state.contracts?.items?.forEach(contract => {
        if( 
          contract?.status 
          && arrValidContractStates.includes(contract?.status) 
          && contract?.status === 'ReadyToAccept'){
            let oneInstallationAdded = false
            contract?.installations?.forEach( installationItem  => {
              if(installationItem?.serialNumber && !oneInstallationAdded){
                if(readyToAcceptCount===0){
                  if(numberOfUnsignedContracts() > 1){
                    arrMessages.push(<li key={'head'+installationItem.serialNumber+uuid.v4()} className="pb-4">Es liegen mehrere Verträge zur Unterzeichnung vor.</li>)
                  }
                  else{
                    arrMessages.push(<li key={'head'+installationItem.serialNumber+uuid.v4()} className="pb-4">Es liegt ein Vertrag zur Unterzeichnung vor.</li>)
                  }
                }
                arrMessages.push(<li key={installationItem.serialNumber+uuid.v4()} className="pb-0"><Link title={installationItem.label+''} to={'/installations/'+installationItem._id}>Anlage "{installationItem.serialNumber}"</Link></li>)    
                readyToAcceptCount ++
                oneInstallationAdded = true
              }
            })
        }
      });
      setMessagesCount(numberOfUnsignedContracts())
      if(arrMessages.length <= 0){
        arrMessages.push(<li key="noMessgaes" className="pb-0">Es liegen keine Meldungen vor.</li>)    
      }
      setMessages(arrMessages)
      }
      , [ state.contractsAreLoaded ]);// eslint-disable-line react-hooks/exhaustive-deps

    let localIcon = <Badge count={messagesCount} offset={[-19, -0]}><img src={icon} alt="" className="icon" /></Badge>

    return (
        <Tile icon={localIcon} headline={headline} content={<div className=" text-lg pl-16"><ul className=" pr-16 overflow-x-hidden max-h-60">{messages}</ul></div>}/>
    );
  }
  
export default TileLocal;
  
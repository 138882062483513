/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveParty = /* GraphQL */ `
  mutation SaveParty($party: PartyInput!) {
    saveParty(party: $party) {
      id
      label
      group
      email
      phoneNumbers
      address {
        label
        street
        houseNumber
        postalCode
        city
        country
      }
      ... on Company {
        companyName
      }
      ... on Person {
        relatedParties {
          id
          label
          group
          email
          phoneNumbers
          address {
            label
            street
            houseNumber
            postalCode
            city
            country
          }
          ... on Company {
            companyName
          }
          ... on Person {
            relatedParties {
              id
              label
              group
              email
              phoneNumbers
              ... on Company {
                companyName
              }
              ... on Person {
                role
                salutation
                firstName
                lastName
              }
            }
            role
            salutation
            firstName
            lastName
          }
        }
        role
        salutation
        firstName
        lastName
      }
    }
  }
`;
export const addSmartMaintenanceContract = /* GraphQL */ `
  mutation AddSmartMaintenanceContract($operatorId: ID!) {
    addSmartMaintenanceContract(operatorId: $operatorId) {
      id
      status
      operator {
        id
        label
      }
      operator_slow {
        id
        label
        group
        email
        phoneNumbers
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        ... on Company {
          companyName
        }
        ... on Person {
          relatedParties {
            id
            label
            group
            email
            phoneNumbers
            ... on Company {
              companyName
            }
            ... on Person {
              role
              salutation
              firstName
              lastName
            }
          }
          role
          salutation
          firstName
          lastName
        }
      }
      installations {
        id
        label
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        formData
        costPositions {
          id
          documentCategory
          installationId
          serialNumber
          comment
          priceInEuro
        }
      }
      documents {
        s3key
        originalFileName
        mimeType
        size
        form {
          ... on PhotoFile {
            documentType
            categories
            selectedInstallations
          }
          ... on DocumentFile {
            documentType
            categories
            selectedInstallations
            title
          }
          ... on ContractFile {
            documentType
            categories
            selectedInstallations
            contractDuration
          }
          ... on BillFile {
            documentType
            categories
            selectedInstallations
            totalCost
            billingDate
          }
        }
      }
      offerDetails {
        selectedComponents {
          maintenanceAndInspection
          emergencyCommunication
          testManagement
          specialOptions
          smartConnect
        }
        placeOfJurisdiction
        totalSum
        specialAgreements
      }
      contractS3Key
    }
  }
`;
export const contractReleased = /* GraphQL */ `
  mutation ContractReleased($contractNumber: ID!) {
    contractReleased(contractNumber: $contractNumber) {
      id
      status
      operator {
        id
        label
      }
      operator_slow {
        id
        label
        group
        email
        phoneNumbers
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        ... on Company {
          companyName
        }
        ... on Person {
          relatedParties {
            id
            label
            group
            email
            phoneNumbers
            ... on Company {
              companyName
            }
            ... on Person {
              role
              salutation
              firstName
              lastName
            }
          }
          role
          salutation
          firstName
          lastName
        }
      }
      installations {
        id
        label
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        formData
        costPositions {
          id
          documentCategory
          installationId
          serialNumber
          comment
          priceInEuro
        }
      }
      documents {
        s3key
        originalFileName
        mimeType
        size
        form {
          ... on PhotoFile {
            documentType
            categories
            selectedInstallations
          }
          ... on DocumentFile {
            documentType
            categories
            selectedInstallations
            title
          }
          ... on ContractFile {
            documentType
            categories
            selectedInstallations
            contractDuration
          }
          ... on BillFile {
            documentType
            categories
            selectedInstallations
            totalCost
            billingDate
          }
        }
      }
      offerDetails {
        selectedComponents {
          maintenanceAndInspection
          emergencyCommunication
          testManagement
          specialOptions
          smartConnect
        }
        placeOfJurisdiction
        totalSum
        specialAgreements
      }
      contractS3Key
    }
  }
`;
export const contractReopened = /* GraphQL */ `
  mutation ContractReopened($contractNumber: ID!) {
    contractReopened(contractNumber: $contractNumber) {
      id
      status
      operator {
        id
        label
      }
      operator_slow {
        id
        label
        group
        email
        phoneNumbers
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        ... on Company {
          companyName
        }
        ... on Person {
          relatedParties {
            id
            label
            group
            email
            phoneNumbers
            ... on Company {
              companyName
            }
            ... on Person {
              role
              salutation
              firstName
              lastName
            }
          }
          role
          salutation
          firstName
          lastName
        }
      }
      installations {
        id
        label
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        formData
        costPositions {
          id
          documentCategory
          installationId
          serialNumber
          comment
          priceInEuro
        }
      }
      documents {
        s3key
        originalFileName
        mimeType
        size
        form {
          ... on PhotoFile {
            documentType
            categories
            selectedInstallations
          }
          ... on DocumentFile {
            documentType
            categories
            selectedInstallations
            title
          }
          ... on ContractFile {
            documentType
            categories
            selectedInstallations
            contractDuration
          }
          ... on BillFile {
            documentType
            categories
            selectedInstallations
            totalCost
            billingDate
          }
        }
      }
      offerDetails {
        selectedComponents {
          maintenanceAndInspection
          emergencyCommunication
          testManagement
          specialOptions
          smartConnect
        }
        placeOfJurisdiction
        totalSum
        specialAgreements
      }
      contractS3Key
    }
  }
`;
export const contractReviewed = /* GraphQL */ `
  mutation ContractReviewed($contractNumber: ID!) {
    contractReviewed(contractNumber: $contractNumber) {
      id
      status
      operator {
        id
        label
      }
      operator_slow {
        id
        label
        group
        email
        phoneNumbers
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        ... on Company {
          companyName
        }
        ... on Person {
          relatedParties {
            id
            label
            group
            email
            phoneNumbers
            ... on Company {
              companyName
            }
            ... on Person {
              role
              salutation
              firstName
              lastName
            }
          }
          role
          salutation
          firstName
          lastName
        }
      }
      installations {
        id
        label
        address {
          label
          street
          houseNumber
          postalCode
          city
          country
        }
        formData
        costPositions {
          id
          documentCategory
          installationId
          serialNumber
          comment
          priceInEuro
        }
      }
      documents {
        s3key
        originalFileName
        mimeType
        size
        form {
          ... on PhotoFile {
            documentType
            categories
            selectedInstallations
          }
          ... on DocumentFile {
            documentType
            categories
            selectedInstallations
            title
          }
          ... on ContractFile {
            documentType
            categories
            selectedInstallations
            contractDuration
          }
          ... on BillFile {
            documentType
            categories
            selectedInstallations
            totalCost
            billingDate
          }
        }
      }
      offerDetails {
        selectedComponents {
          maintenanceAndInspection
          emergencyCommunication
          testManagement
          specialOptions
          smartConnect
        }
        placeOfJurisdiction
        totalSum
        specialAgreements
      }
      contractS3Key
    }
  }
`;
export const saveOfferDetails = /* GraphQL */ `
  mutation SaveOfferDetails($offerDetails: OfferDetailsInput!) {
    saveOfferDetails(offerDetails: $offerDetails) {
      selectedComponents {
        maintenanceAndInspection
        emergencyCommunication
        testManagement
        specialOptions
        smartConnect
      }
      placeOfJurisdiction
      totalSum
      specialAgreements
    }
  }
`;
export const saveAutocompleteItem = /* GraphQL */ `
  mutation SaveAutocompleteItem($item: AutocompleteItemInput!) {
    saveAutocompleteItem(item: $item) {
      id
      label
    }
  }
`;
export const saveInstallation = /* GraphQL */ `
  mutation SaveInstallation(
    $contractNumber: ID!
    $installation: InstallationInput!
  ) {
    saveInstallation(
      contractNumber: $contractNumber
      installation: $installation
    ) {
      id
      label
      address {
        label
        street
        houseNumber
        postalCode
        city
        country
      }
      formData
      costPositions {
        id
        documentCategory
        installationId
        serialNumber
        comment
        priceInEuro
      }
    }
  }
`;
export const deleteInstallation = /* GraphQL */ `
  mutation DeleteInstallation($contractNumber: ID!, $installationId: ID!) {
    deleteInstallation(
      contractNumber: $contractNumber
      installationId: $installationId
    )
  }
`;
export const saveDocument = /* GraphQL */ `
  mutation SaveDocument($fileItem: DocumentRefInput!) {
    saveDocument(fileItem: $fileItem) {
      s3key
      originalFileName
      mimeType
      size
      form {
        ... on PhotoFile {
          documentType
          categories
          selectedInstallations
        }
        ... on DocumentFile {
          documentType
          categories
          selectedInstallations
          title
        }
        ... on ContractFile {
          documentType
          categories
          selectedInstallations
          contractDuration
        }
        ... on BillFile {
          documentType
          categories
          selectedInstallations
          totalCost
          billingDate
          costPositions {
            id
            documentCategory
            installationId
            serialNumber
            comment
            priceInEuro
          }
        }
      }
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($contractNumber: ID!, $documentId: ID!) {
    deleteDocument(contractNumber: $contractNumber, documentId: $documentId)
  }
`;
export const saveCostPosition = /* GraphQL */ `
  mutation SaveCostPosition(
    $contractNumber: ID!
    $costPosition: CostPositionInput!
  ) {
    saveCostPosition(
      contractNumber: $contractNumber
      costPosition: $costPosition
    ) {
      id
      documentCategory
      installationId
      serialNumber
      comment
      priceInEuro
    }
  }
`;
export const deleteCostPosition = /* GraphQL */ `
  mutation DeleteCostPosition(
    $contractNumber: ID!
    $costPosition: CostPositionInput!
  ) {
    deleteCostPosition(
      contractNumber: $contractNumber
      costPosition: $costPosition
    )
  }
`;
export const setAssetTitle = /* GraphQL */ `
  mutation SetAssetTitle($input: AssetTitleInput!) {
    setAssetTitle(input: $input)
  }
`;
import jwt_decode from "jwt-decode";
import { Auth } from "aws-amplify";
import { AUTH_USER_TOKEN_KEY } from "../utils/constants";
import { Storage } from "aws-amplify";
import awsconfig  from "./../config";
import {
  BillFile,
  ContractFile,
  DocumentFile,
  DocumentFormData,
  DocumentRef,
  GeneratedDocumentFile,
  Installation,
  PhotoFile,
  SmartMaintenanceContractPage,
  SmartMaintenanceContract,
  Party,
  Person,
  Company,
} from "../graphql/API";
import ImageLoader from '../components/Tables/imageLoader'

export const get_api_endpoint = () => {
  if( (awsconfig as any).aws_api_endpoint){
    return  (awsconfig as any).aws_api_endpoint
  }
  return ''
}

export const get_api_endpoint_iot = () => {
  if( (awsconfig as any).aws_api_endpoint_iot){
    return  (awsconfig as any).aws_api_endpoint_iot
  }
  return ''
}

export const get_api_endpoint_iot_management = () => {
  if( (awsconfig as any).aws_api_endpoint_iot_management){
    return  (awsconfig as any).aws_api_endpoint_iot_management
  }
  return ''
}

export const get_env = () => {
  if( (awsconfig as any).env){
    return  (awsconfig as any).env
  }
  return ''
}



/*
export const loadContracts = async(lastResult?:GraphQLResult<Query>):Promise<any>=> {
  //Amplify.configure(awsconfig);
  let before:String | null = null
  if(lastResult && lastResult.data?.contracts?.before ){
    before = lastResult.data?.contracts?.before
  }
  const res = await (API.graphql({ query: queries.contracts , variables:{ page:{
    limit:20,
    before:before
  } }}) as GraphQLResult<Query> );

  if(res.data?.contracts?.items != null){

    let dataGlobal:Partial<GlobalStateInterface>
    
    // combine: thistime loaded and the allready existing ones
    let contracts:Maybe<SmartMaintenanceContract>[] | null | undefined = null  

    if(lastResult){
      contracts = lastResult.data?.contracts?.items
      if(contracts){
        contracts = contracts.concat(res.data.contracts.items)
      }
    }
    else{
      contracts = res.data.contracts.items
    }

    dataGlobal = {
      contracts:{
        items: contracts 
      } 
    } 

    if(res.data?.contracts.before === null){
      dataGlobal.contractsAreLoaded = true
      return dataGlobal
    }
    else{
      if(res.data.contracts){
        res.data.contracts.items = contracts
        return loadContracts(res)  
      }
    }
  }
}

export const promiseLoadContracts = new Promise(  async function(resolve, reject) {
  const contracts = await loadContracts()
  resolve(contracts as Partial<GlobalStateInterface>);
})
*/
/**
 * helper method to validate  user token
 *
 * @returns {boolean}
 */
export const validateToken = (): boolean => {
  let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);

  if (!token) {
    setLoginInfoToBodyClasslist(false);
    return false;
  }
  try {
    const decodedJwt: any = jwt_decode(token);
    let returnValue = decodedJwt.exp >= Date.now() / 1000;
    returnValue
      ? setLoginInfoToBodyClasslist(true)
      : setLoginInfoToBodyClasslist(false);
    return returnValue;
  } catch (e) {
    console.log(e);
    setLoginInfoToBodyClasslist(false);
    return false;
  }
};

const setLoginInfoToBodyClasslist = (isLoggenIn: boolean) => {
  if (isLoggenIn) {
    document.body.classList.remove("UserLoggedOut");
    document.body.classList.add("UserLoggedIn");
  } else {
    document.body.classList.remove("UserLoggedIn");
    document.body.classList.add("UserLoggedOut");
  }
};

export const handleLogout = async () => {
  Auth.signOut({ global: true })
    .then((user) => {
      localStorage.removeItem(AUTH_USER_TOKEN_KEY);
      window.location.href = "/login";
    })
    .catch((err) => {
      //console.log(err)
      validateToken()
    });
};

export function downloadBlob(blob: any, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

export const openDocument = async (
  document: any,
  download: boolean = false
) => {
  console.log(document);
  if (download) {
    const result = await Storage.get(document.key, { download: true });
    if (typeof result === "object") {
      //@ts-ignore
      downloadBlob(result.Body, document.originalFileName);
    }
  } else {
    Storage.get(document.key).then((response) => {
      console.log(response);
      window.open(response.toString());
    });
  }
};

export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export const getDocumentsOverlay = (
  x: number,
  y: number,
  width: number,
  showFile: any | null,
  fileUrl: string
) => {
  let mouseIsAtRightSide = true;
  if (x > 0 && width / 2 > x) {
    mouseIsAtRightSide = false;
  }

  var overlayPosition = {};

  if (mouseIsAtRightSide) {
    overlayPosition = { left: "100px", top: y + "px", width: "500px" };
  } else {
    overlayPosition = { right: "100px", top: y + "px", width: "500px" };
  }

  if (showFile.mimetype === "application/pdf") {
    overlayPosition = { ...overlayPosition, height: "400px" };
    return (
      <iframe
        title={showFile.originalFileName}
        className="documentOverlay"
        src={fileUrl.toString()}
        style={overlayPosition}
      ></iframe>
    );
  } else {
    if (mouseIsAtRightSide) {
      overlayPosition = { left: "100px", top: y + "px", width: "500px" };
    } else {
      overlayPosition = { right: "100px", top: y + "px", width: "500px" };
    }
    return (
      <div className="documentOverlay" style={overlayPosition}>
        <img src={fileUrl} alt={showFile.originalFileName} />
      </div>
    );
  }
};

export const getDocumentsPreview = (
  showFile: any | null,
) => {
  if(!showFile?.mimeType){
    return false
  }

  return <ImageLoader document={showFile}/>
};

export const getInstallationIdsByDocument = (
  document: DocumentRef,
  contracts: SmartMaintenanceContractPage | undefined,
  htmlWrap: Boolean = true
) => {
  var installationIds: any[] = [];
  contracts?.items?.forEach(function (contractItem, index) {
    contractItem?.installations?.forEach(function (intallationItem, index) {
      if (
        intallationItem?._id &&
        !isGenerated(document.form) &&
        document.form?.selectedInstallations.includes(intallationItem?._id)
      ) {
        if(htmlWrap){
          installationIds.push(
            <div key={document.s3Key + index}>
              {intallationItem?.serialNumber}
            </div>
          );  
        }
        else{
          installationIds.push(intallationItem?.serialNumber)
        }
      }
    });
  });
  return installationIds;
};

export const getInstallationDbIdsByDocument = (
  document: DocumentRef,
  contracts: SmartMaintenanceContractPage | undefined
  ) => {
  var installationIds: any[] = [];
  contracts?.items?.forEach(function (contractItem, index) {
    contractItem?.installations?.forEach(function (intallationItem, index) {
      if (
        intallationItem?._id &&
        !isGenerated(document.form) &&
        document.form?.selectedInstallations.includes(intallationItem?._id)
      ) {
          installationIds.push(intallationItem?._id)
      }
    });
  });
  return installationIds;
};

export const getContractByInstallation = (
  intallation?: Installation,
  contracts?: SmartMaintenanceContractPage | undefined
) => {
  let contract: SmartMaintenanceContract

  let testDate = new Date()
  contract = {_id:'',contractNumber:'',operator:{_id:'',label:''}, createdAt:testDate,status:'Accepted',timeline:[]}
  contracts?.items?.forEach(function (contractItem, index) {
    contractItem?.installations?.forEach( function (installationItem,index){
      if(installationItem?._id === intallation?._id){
        contract = contractItem
      }
    })
  })
  return contract
};

export const getSignedContractDocumentByContract = (
  contract: SmartMaintenanceContract | undefined
) => {
  let generatedContract : DocumentRef| undefined
  contract?.documents?.forEach(function (documentItem, index) {
    if(
      documentItem.form?.__typename === 'ContractFile'
      && documentItem.form?.categories?.includes('signed-contract')
    ){
      generatedContract = documentItem
    }
  })
  return generatedContract
}

export const getGeneratedContractDocumentByContract = (
  contract: SmartMaintenanceContract | undefined
) => {
  let generatedContract : DocumentRef| undefined
  contract?.documents?.forEach(function (documentItem, index) {
    if(
      documentItem.form?.__typename === 'GeneratedDocumentFile'
      && documentItem.originalFileName.startsWith('Vertragsvorscha')
    ){
      generatedContract = documentItem
    }
  })
  return generatedContract
}

export function onlyUnique(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index;
}

export function isPerson(
  party: any | null | undefined
): party is Person {
  if(party?.firstName ){
    return true;
  }
  else{
    return false
  }
}

export function isCompany(
  party:  any | Company | null | undefined
): party is Company {
  if(party?.companyName ){
    return true;
  }
  else{
    return false
  }
}

export function isParty(
  party: Party | null | undefined
): party is Party {
  return party?._id !== null;
}

export function isDocument(
  doc: DocumentFormData | null | undefined
): doc is DocumentFile {
  return doc?.__typename === "DocumentFile";
}

export function isPhoto(
  doc: DocumentFormData | null | undefined
): doc is PhotoFile {
  return doc?.__typename === "PhotoFile";
}

export function isContract(
  doc: DocumentFormData | null | undefined
): doc is ContractFile {
  return doc?.__typename === "ContractFile";
}

export function isBill(
  doc: DocumentFormData | null | undefined
): doc is BillFile {
  return doc?.__typename === "BillFile";
}

export function isGenerated(
  doc: DocumentFormData | null | undefined
): doc is GeneratedDocumentFile {
  return doc?.__typename === "GeneratedDocumentFile";
}
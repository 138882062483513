import * as React from 'react';
import { Link,useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form , Spin, Input, Button, notification } from 'antd';
import { UserOutlined ,LockFilled,LoadingOutlined } from '@ant-design/icons';
import logo from './../../assets/images/logo_aufzughelden.svg'
import './LoginForm.css'
import ArrowButton from './../../assets/images/arrow_button.svg'
import {validateNextPassword} from './../../utils/validators'
import { handleLogout } from './../../utils/helpers';
import { AUTH_USER_TOKEN_KEY,USER_CHOICE_LANGUAGE,AUTH_IOT_USER } from '../../utils/constants';
import { useGlobalState, GlobalStateInterface } from "../../utils/globlStateProvider";
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLoginForm } from './LoginFormLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import Footer from '../Footer';
import axios from 'axios'
import { get_api_endpoint } from '../../utils/helpers';

I18n.putVocabularies(mergeDeepRight(stringLoginForm,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

const LoginContainer = () => {
  const { setState } = useGlobalState();
  let history = useHistory();
  const [isloading, setisLoading] = React.useState(false)
  const [isNewPasswordRequired, setIsNewPasswordRequired] = React.useState(false)
  const [userNewPasswordRequired, setUserNewPasswordRequired] = React.useState()

  const onFinishCompletePassword = (values: any) => {
    setisLoading(true)
    let { password  } = values;

    Auth.completeNewPassword(userNewPasswordRequired,password).then(
      user => {
        localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);

          // refresh iot token
          axios.get(get_api_endpoint() + '/iot/auth',  {headers: {
            'authorization': user.signInUserSession.accessToken.jwtToken
            }})
            .then((response) => { 
              localStorage.setItem(AUTH_IOT_USER,JSON.stringify(response.data) );
            });

        notification.success({
          message: I18n.get('notificationSuccess'),
          description: I18n.get('notificationMessageLoginSuccess'),
          placement: 'topRight',
          duration: 5
        });
        history.push('/')
      }
    )
  }

  const onFinish = (values: any) => {
    
    setisLoading(true)
    let { username, password,  } = values;

    Auth.signIn(username, password)
    .then( user => {
      if(user.challengeName && user.challengeName ===  'NEW_PASSWORD_REQUIRED'){
        // new user with temp password
        console.log(user)
        setIsNewPasswordRequired(true)
        setUserNewPasswordRequired(user)
        setisLoading(false)
      }
      else{
        // valid User
        window.scrollTo(0, 0);
        localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);

          // refresh iot token
          axios.get(get_api_endpoint() + '/iot/auth',  {headers: {
            'authorization': user.signInUserSession.accessToken.jwtToken
            }})
            .then((response) => { 
              localStorage.setItem(AUTH_IOT_USER, JSON.stringify( response.data));
            });

        // user needs to be part of operator group
        Auth.currentAuthenticatedUser()
        .then(data => {
          if(!data.signInUserSession.accessToken.payload['cognito:groups']?.includes('operator')){
            notification.error({
              message: I18n.get('notificationError'),
              description: I18n.get('notificationMessageLoginNoRights'),
              placement: 'topRight',
              duration: 5
            });            
            handleLogout()
          }
          else{
            let dataGlobal:Partial<GlobalStateInterface> = {username:user.attributes.email,name:user.attributes.name,loadAndReloadInitDone:true}
            setState((prev) => ({ ...prev, ...dataGlobal }));
            notification.success({
              message: I18n.get('notificationSuccess'),
              description: I18n.get('notificationMessageLoginSuccess'),
              placement: 'topRight',
              duration: 5
            });
            
            history.push('/')
          }
        })
        .catch(err => {
          console.log(err.code)    
          notification.error({
            message: I18n.get('notificationError'),
            description: I18n.get('notificationMessageLoginError'),
            placement: 'topRight'
          });    
          console.log(err);
          setisLoading(false)
        });
      }
    })
    .catch(err => {
      console.log(err.code)
      var message = ''
      switch (err.code) {
        case "NotAuthorizedException":
          message = I18n.get('cognitoStateNotAuthorizedException')
          break;
        case "LimitExceededException":
          message = I18n.get('cognitoStateLimitExceededException')
          break;
        default:
          message = err.message
          break;
      }

      notification.error({
        message: I18n.get('notificationError'),
        description: message,
        placement: 'topRight'
      });

      console.log(err);
      setisLoading(false)
    });
    
  };

  const onFinishFailed = (errorInfo: any) => {
    setisLoading(false)
    console.log('Failed:', errorInfo);
  };

    return (
      <React.Fragment>
        <div className="pt-16 m:pt-48">
          <img src={logo} alt="Augzuhelden Logo" className="m-auto h-20 m:h-28 mb-12"/>
        </div>
        <div className="loginForm bg-white m-auto">
          {isNewPasswordRequired ? 
          <>
            <h4 className="text-left pb-4">Passwort ändern</h4>
            <Form 
              requiredMark={'optional'}
              initialValues={{ remember: true }}
              onFinish={onFinishCompletePassword}
              onFinishFailed={onFinishFailed}
              className="login-form">

              <Form.Item
                name="password"
                label={I18n.get('labelFieldPwd')}
                labelAlign="left"
                labelCol={{span:24}}
                rules={[{ required: true, validator: validateNextPassword }]} >
                <Input placeholder={I18n.get('labelFieldPwd')} autoComplete="new-password" type="password" allowClear prefix={<LockFilled/>}/>
              </Form.Item>

              <Form.Item className="text-center">
                <div className=" text-left pt-4">
                  <div>
                    <Button
                      //type="primary"
                      disabled={isloading}
                      htmlType="submit"
                      className= {isloading ? 'sbButton isLoading' : 'sbButton isNotLoading'}
                    >
                      {isloading ? <Spin indicator={<LoadingOutlined/>} /> : <>{I18n.get('labelButtonChangPwd')} <img className="" alt="" src={ArrowButton}  /></>}
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </>          
          : 
          <>
            <h4 className="text-left pb-4">{I18n.get('loginTitle')}</h4>
            <Form 
              requiredMark={'optional'}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="login-form">
              <Form.Item
                name="username"
                label={I18n.get('labelFieldUser')}
                labelAlign="left"            
                labelCol={{span:24}}
                wrapperCol={{span:24}}
                rules={[{ required: true, type: 'string', min: 2 ,message:I18n.get('ruleMessageLogin') }]} >
                <Input placeholder="E-Mail" autoComplete="username" size="large" allowClear prefix={<UserOutlined />} />
              </Form.Item>

              <Form.Item
                name="password"
                label={I18n.get('labelFieldPwd')}
                labelAlign="left"
                labelCol={{span:24}}
                rules={[{ required: true, type: 'string', min: 2 ,message:I18n.get('ruleMessagPwd') }]} >
                <Input placeholder={I18n.get('labelFieldPwd')} autoComplete="current-password" type="password" allowClear prefix={<LockFilled/>}/>
              </Form.Item>

              <Form.Item className="text-center">
                <div className=" text-left pt-4">
                  <div>
                    <Button
                      //type="primary"
                      disabled={isloading}
                      htmlType="submit"
                      className= {isloading ? 'sbButton isLoading' : 'sbButton isNotLoading'}
                    >
                      {isloading ? <Spin indicator={<LoadingOutlined/>} /> : <>{I18n.get('labelButtonLogin')} <img className="" alt="" src={ArrowButton}  /></>}
                    </Button>
                  </div>
                  <div className=" mt-2">{I18n.get('loginPwdForgot')} <Link className="login-form-forgot text-blueLight pl-2" to="/forgot-password">{I18n.get('loginPwdForgotSet')}</Link></div>
                </div>
              </Form.Item>
            </Form>
          </>
          }

        </div>
        <div className="flex-grow"></div>
        <Footer />
      </React.Fragment>
    );
  }

export default LoginContainer;
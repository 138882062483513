import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../utils/globalLocalization';
import { strings as stringLocal } from './InstallationDetailLocalization';
import { USER_CHOICE_LANGUAGE } from './../utils/constants';
import { mergeDeepRight } from 'ramda';
import contractDummy from './../assets/images/contract_dummy.png'
import { useHistory, useParams } from "react-router-dom";
import { useGlobalState, GlobalStateInterface } from "../utils/globlStateProvider";
import { useState ,useEffect, useRef} from 'react';
import { Query , Installation , SmartMaintenanceContractPage, DocumentRef, SmartMaintenanceContract} from '../graphql/API';
import { API, GraphQLResult } from '@aws-amplify/api'
import * as queries from './../graphql/queries';
import './InstallationDetail.css';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import mapStyles from ".//Tile/TileMapMapStyles"
import { EnvironmentTwoTone } from '@ant-design/icons';
import React from 'react';
import { Badge, Spin, Tabs } from 'antd';
import arrowLeft from '../assets/images/icons/arrow_left.svg'
import { getContractByInstallation, getGeneratedContractDocumentByContract, getSignedContractDocumentByContract, openDocument } from '../utils/helpers';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import Documents from './InstallationTabs/Documents'
import ContractWin from './InstallationTabs/ContractWin'
import IoT from './InstallationTabs/IoT'
import Bluekit from './InstallationTabs/Bluekit'
import EditableTitle from './InstallationDetails/editableTitle'

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
I18n.setLanguage(lang)
moment.locale(lang? lang: 'de') ;

type InstallationParams = {
  id: string;
};

function Installations() {

  const { state, setState } = useGlobalState()
  const [contractFile, setContractFile] = useState<DocumentRef | undefined>(undefined)
  const { id } = useParams<InstallationParams>();
  const [installation, setInstallation] = useState<Installation|undefined>(undefined)
  const [hasIoT, setHasIoT] = useState<boolean>(false)
  const [geoCoordianteLoaded, setGeoCoordianteLoaded] = useState<boolean>(false)
  const [contract, setContract] = useState<SmartMaintenanceContract|undefined>(undefined)
  type objMarker = { lat:Number,lng:Number,name:string ,key:string, show:boolean};
  const [markers, setMarkers] = React.useState<objMarker[]>([])
  let history = useHistory();

  var mapsApiCode = 'AIzaSyDPpxzfV8E4nubAporwnJgJ82DPZPRTGmk'
  Geocode.setApiKey(mapsApiCode);
  Geocode.setLanguage("en")

  const loadContracts = async() => {
    const res = await (API.graphql({ query: queries.contracts , variables:{ page:{}, filter:{} }}) as GraphQLResult<Query> );
    if(res.data?.contracts?.items != null){
      let dataGlobal:Partial<GlobalStateInterface> = {
        contracts: res.data?.contracts as SmartMaintenanceContractPage,
        contractsAreLoaded:true
      } 
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }
  if(!state.contractsAreLoaded){ loadContracts() }

  useEffect(() => {
    document.body.classList.add('isInstallationDetailPage');
    return () => {
      document.body.classList.remove('isInstallationDetailPage');
    };
  }, [ ]);

  useEffect(() => {
    state.contracts?.items?.forEach( itemContract => {
      itemContract?.installations?.forEach( itemInstallation => {
        if(itemInstallation?._id === id){
          setInstallation(itemInstallation)
          if(itemInstallation.iotBox?.Dashboard_activated__c && (itemInstallation.iotBox.Name && itemInstallation.iotBox.Name.length > 5 )){
            setHasIoT(true)
          }
          
          let installationsContract = getContractByInstallation(itemInstallation,state.contracts)
          setContract(installationsContract)
          if(installationsContract.status === 'Accepted'){
            setContractFile(getSignedContractDocumentByContract(installationsContract))
          }
          else{
            setContractFile(getGeneratedContractDocumentByContract(installationsContract))
          }

          // if no coordinates are availalble then get it by google service
          if(itemInstallation?.address?.coordinates?.lat){
            let tmpObj = {} as objMarker
            tmpObj.key = itemInstallation._id
            tmpObj.lat = itemInstallation.address.coordinates.lat
            tmpObj.lng = itemInstallation.address.coordinates.lng
            tmpObj.name = installation?.label || ''
            tmpObj.show = false
            setMarkers([tmpObj])
            setGeoCoordianteLoaded(true)
          }
          else if(itemInstallation?.address?.label){
            Geocode.fromAddress(itemInstallation?.address?.label).then(
              (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                let tmpObj = {} as objMarker
                tmpObj.key = itemInstallation._id
                tmpObj.lat = lat
                tmpObj.lng = lng
                tmpObj.name = installation?.label || ''
                tmpObj.show = false
                setMarkers([tmpObj])
                setGeoCoordianteLoaded(true)
              },
              (error) => {
                console.error(error);
              }
            );            
          }
        }
      })
    })
  }, [state.contractsAreLoaded]);// eslint-disable-line react-hooks/exhaustive-deps
  
  const showInfowindow = (id:any) =>{
    //const index = markers.findIndex((e) => e.key === id);
    let myMarkers =  JSON.parse(JSON.stringify( markers ))
    myMarkers.forEach((element: { show: boolean; }) => {
      element.show = false
    });
    //myMarkers[index].show = !markers[index].show
    setMarkers(myMarkers)
  }

  const MapMarker = ({ text,show,id }:any ) => {
    const markerRef = useRef(null);
    return (
      <div className="mapMarker"  ref={markerRef}>
        <EnvironmentTwoTone onClick={()=>{ showInfowindow(id)}} alt={text} twoToneColor="#34495E" style={{ fontSize: '26px' }}/>
        {show && <div className="googleMapsInfoWindow">{text}</div>}
      </div>
      )
    };

  // Re-center map when resizing the window
  const bindResizeListener = (map: any, maps: { Marker?: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; event?: any; }, bounds: any) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
        map.setZoom(14);
      });
    });
  };

  // Return map bounds based on list of places
  const getMapBounds = (map: any, maps: { Marker?: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; LatLngBounds?: any; LatLng?: any; }) => {
    const bounds = new maps.LatLngBounds();
    markers.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  const centerMap = (map: any, maps: { Marker: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; },markers: { lat: Number; lng: Number; name: string; key: string; }[]) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps);
    // Fit map to bounds
    map.fitBounds(bounds);
    map.setZoom(14)
    
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
   return [];
  };

  const getMap = () => {
    return <GoogleMapReact
      bootstrapURLKeys={{ key: mapsApiCode}}
      defaultCenter={{lat:59.95,lng:30.33}}
      defaultZoom={4}
      yesIWantToUseGoogleMapApiInternals={true}
      options={{styles:mapStyles}}
      onGoogleApiLoaded={({map, maps}) => centerMap(map, maps, markers)}
      >
      {markers.map((place) => (
          <MapMarker
            key={place.key}
            id={place.key}
            text={place.name}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
          />
        ))}
      </GoogleMapReact>
    }
    
  function getLabelTranslation(label:string,value:string){

    switch (label) {
      case 'Aufzugstype':

        switch (value) {
          case 'Passenger Elevator':
            return 'Personenaufzug'
          case 'Cargo Elevator':
            return 'Lastenaufzug'
          case 'Hydraulic Ramp':
            return 'Hydraulische Rampe'
          default:
            return value
        }

      case 'Aufzugart':

        switch (value) {
          case 'Rope':
            return 'Seil'
          case 'Direct Hydraulic Systems':
            return 'Direktes Hydrauliksystem'
          case 'Indirect Hydraulic Systems':
            return 'Indirektes Hydrauliksystem'
          default:
            return value
        }

      default:
        return value
    }
  }

  const getDataElement = (label: string,value: string) => {
    return <div className="flex" key={label}><div>{label}</div><div>{getLabelTranslation(label,value)}</div></div>
  }

  const getInstallationData = () => {

    let arrData = []

    if(installation?.formData){ 
      let installationData = installation?.formData
      if(installationData?.Baujahr__c){ arrData.push(getDataElement('Baujahr',installationData?.Baujahr__c)) }
      if(installationData?.Elevator_Construction__c){ arrData.push(getDataElement('Aufzugstype',installationData?.Elevator_Construction__c)) }
      if(installationData?.Elevator_Type__c){ arrData.push(getDataElement('Aufzugart',installationData?.Elevator_Type__c)) }
      if(installationData?.Number_Of_Stops__c){ arrData.push(getDataElement('Etagen',installationData?.Number_Of_Stops__c.toString())) }
      if(installationData?.Number_Of_Doors__c){ arrData.push(getDataElement('Türen',installationData?.Number_Of_Doors__c.toString())) }
      if(installationData?.Load_Capacity_kg__c){ arrData.push(getDataElement('Last (Kg)',installationData?.Load_Capacity_kg__c.toString())) }
      if(installationData?.Max_Persons__c){ arrData.push(getDataElement('Last (Personen)',installationData?.Max_Persons__c.toString())) }
      if(installationData?.Main_Access__c){ arrData.push(getDataElement('Hauptzugang',installationData?.Main_Access__c.toString())) }
      if(installationData?.Speed_m_s__c){ arrData.push(getDataElement('Geschw. (m/s)',installationData?.Speed_m_s__c.toLocaleString('de-DE'))) }  
    }
    
    return arrData
  }

  const getContractdata = () => {
    let created: String | undefined = undefined
    contract?.timeline?.forEach( itemTimeline => {
      if(itemTimeline.event === 'ContractReleased'){
        created = moment(itemTimeline.createdAt).format('L')
      }
    })

    return <>
      <div className="flex">
        <div>Angebot erstellt am</div>
        <div>{created}</div>
      </div>
      {contract?.status === 'ReadyToAccept' && 
          <div className="flex">
            <div>Status</div>
            <div>{(contract?.status === 'ReadyToAccept')? <span className=" text-warnRed">Nicht abgeschlossen</span> : 'Abgeschlossen'}</div>
          </div>
        }
    </>
  }

  const { TabPane } = Tabs;

  const GetTabs = () => {
    return <>
          {contract?.status === 'ReadyToAccept' && 
            <TabPane tab={<Badge dot>Jetzt Vertrag abschließen</Badge>} key="1" className="bg-white">
              <ContractWin installation={installation} />
            </TabPane>
          }

          <TabPane tab="Aufzug-Dokumente" key="2">
            <Documents installation={installation} />
          </TabPane>
          <TabPane tab={'Nachrichten'} key="3" disabled={true}>
            Nachrichten
          </TabPane>
          <TabPane tab={'IoT'} key="5" disabled={hasIoT? false : true}>
            <IoT installation={installation}/>
          </TabPane>
          <TabPane tab={'Bluekit'} key="6" disabled={false}>
            <Bluekit />
          </TabPane>
        </> 
  }

  return (
    <>
      <section className="bg-white">
        <div className="Content">
          <div>
            <h3 className="flex place-items-start  ">
              <img src={arrowLeft} onClick={ ()=>  history.goBack() } alt="" className="arrow link pr-4 place-self-center" />
              {I18n.get('titleInstallationDetail')}
              {installation && <EditableTitle installation={installation} />}
            </h3>
            <p className="pt-8 pl-4 s2">{installation?.address.label} <span className="text-greyLight  text-xs">({installation?.serialNumber})</span></p>
            <div className="subPageContentContainer flex pl-4 justify-between flex-wrap">
              <div className="rowMap">
                {(state.contractsAreLoaded && geoCoordianteLoaded)? getMap() : <Spin size="large" /> }
              </div>
              <div className="rowData">
                <span className="title">Anlagendaten</span>
                {getInstallationData()}
              </div>
              <div className="rowContract flex-col flex-grow">
                <div className="container">
                  <span className="title">Vertragsdaten</span>
                  {getContractdata()}
                </div>
                <div className="contract self-stretch pt-8">
                    <img className="cursor-pointer" onClick={()=>openDocument({key:contractFile?.s3Key,originalFileName:contractFile?.originalFileName},false)} src={contractDummy} alt=""/>
                </div>
              </div>            
            </div>
          </div>
        </div>
      </section>
      <section className="Content bg-greyLighter pt-20">
        <Tabs defaultActiveKey="1" tabPosition="top" type="card" size="large" className="mb-0 overflow-visible">{GetTabs()}</Tabs>
      </section>
    </>
  );
}

export default Installations;

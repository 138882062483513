import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLocal } from './../DocumentsLocalization';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import { mergeDeepRight } from 'ramda';
import { Installation } from '../../graphql/API';
import { useEffect, useState } from 'react';

import UsageChart from './IoT/UsageChart'
import TurnsChart from './IoT/TurnsChart'
import TurnsBetweenLevelsChart from './IoT/TurnsBetweenLevelsChart'
import GeneralStatistic from './IoT/GeneralStatistic'
import LevelVisualization from './IoT/LevelVisualization'
import Availibility from './IoT/Availibility'
import Status from './IoT/Status'
import { device } from 'aws-iot-device-sdk';

import {DoorSouth, DoorNorthSouth} from './IoT/door-animations'

import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import './IoT.css';
import React from 'react';
import { getAuthIotUser, initiateMqqtMessagesByApi, initLiveFeed, isValidDoorConfiguration, loadDevice, loadDurations, loadPastEvents, loadRunsOverTime,loadDoorCyclesCount,loadRunsPerFloor, loadRunsBetweenAllFloors } from '../../utils/helperIoT';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

const UsageChartMemo = React.memo(UsageChart)
const TurnsChartMemo = React.memo(TurnsChart)
const TurnsBetweenLevelsChartMemo = React.memo(TurnsBetweenLevelsChart)

function IoT(props:{installation:Installation|undefined}) {
  
  const { RangePicker } = DatePicker;
  const [doorSouthOpen, setDoorSouthOpen] = useState(true)
  const [doorNorthOpen, setDoorNorthOpen] = useState(true)

  const [deviceId, setDeviceId] = useState('')
  const [from, setFrom] = useState(moment().subtract(7, 'days').toISOString())
  const [to, setTo] = useState(moment().toISOString())
  const [generalStatistic, setGeneralStatistic] = useState({})
  const [runsOverTime, setRunsOverTime] = useState({})
  const [doorCyclesCount, setDoorCyclesCount] = useState({})
  const [runsPerFloor, setRunsPerFloor] = useState({})
  const [runsBetweenAllFloors, setRunsBetweenAllFloors] = useState({})
  const [ioTDevice, setIoTDevice] = useState<any>({})
  const [pastEvents, setPastEvents] = useState({})
  const [liveMessages, setLiveMessages] = useState<any[]>()
  const [lastFiredliveMessages, setlastFiredliveMessages] = useState<any>({})

  const [isLoadingDuration, setIsLoadingDuration] = useState(false)
  const [isLoadingDecice, setIsLoadingDecice] = useState(false)
  const [isLoadingRunsOverTime, setIsLoadingRunsOverTime] = useState(false)
  const [isLoadingRunsBetweenAllFloors, setIsLoadingRunsBetweenAllFloors] = useState(false)
  const [isLoadingPastEvents, setIsLoadingPastEvents] = useState(false)
  const [isLoadingDoorCyclesCount, setIsLoadingDoorCyclesCount] = useState(false)
  const [isLoadingRunsPerFloor, setIsLoadingRunsPerFloor] = useState(false)

  const [liveFeedActivated,setLiveFeedActivated] = useState(false)
  const [mqttClient, setMqttClient] = useState<device>()
  const [mqttConnectionLost,setMqttConnectionLost] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log("refresh livefeed runtime > 30 minutes")
      initiateMqqtMessagesByApi(deviceId,setLiveFeedActivated)
    }, (1000 * 60 * 25));
    return () => clearInterval(interval);
  }, [liveFeedActivated]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(deviceId.length>0){
      loadDurations( deviceId, from, to, setIsLoadingDuration ,setGeneralStatistic )
      loadRunsOverTime( deviceId, from, to, setIsLoadingRunsOverTime ,setRunsOverTime )
      loadRunsBetweenAllFloors( deviceId, from, to, setIsLoadingRunsBetweenAllFloors ,setRunsBetweenAllFloors )  
      loadRunsPerFloor(deviceId, from , to , setIsLoadingRunsPerFloor, setRunsPerFloor)
      loadDoorCyclesCount(deviceId, from , to , setIsLoadingDoorCyclesCount, setDoorCyclesCount)
    }
  }, [from,to]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(props.installation?.iotBox?.Name ){   
      setDeviceId(props.installation.iotBox.Name)
      //setDoor1Open(true)
      //setDoor2Open(false)
    }
    //setDeviceId('KREQNS6UFALCS') // vamed ok
    //setDeviceId('KRERPHYKFFMHQ') // vamed nok
    
  }, [props.installation]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if(mqttClient !== undefined){
        mqttClient?.unsubscribe('identities/'+getAuthIotUser('clientId')+'/from_platform')
      }
    }
  }, [mqttClient]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if(deviceId.length>0){
      loadDurations( deviceId, from, to, setIsLoadingDuration ,setGeneralStatistic )
      loadRunsOverTime( deviceId, from, to, setIsLoadingRunsOverTime ,setRunsOverTime )
      loadRunsBetweenAllFloors( deviceId, from, to, setIsLoadingRunsBetweenAllFloors ,setRunsBetweenAllFloors )
      loadDevice(deviceId,setIsLoadingDecice,setIoTDevice)
      loadPastEvents(deviceId,moment().subtract(7, 'days').toISOString(),moment().toISOString(),setIsLoadingPastEvents,setPastEvents)
      loadRunsPerFloor(deviceId, from , to , setIsLoadingRunsPerFloor, setRunsPerFloor)
      loadDoorCyclesCount(deviceId, from , to , setIsLoadingDoorCyclesCount, setDoorCyclesCount)
      initLiveFeed(deviceId, setLiveFeedActivated,setMqttClient,setlastFiredliveMessages)
    }
  }, [deviceId]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(parseInt(ioTDevice.ragMqtt) === 1){
      setMqttConnectionLost(true)
    }
    else{
      setMqttConnectionLost(false)
    }

  }, [ioTDevice]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    // add message only if its for the current device
    // its required that the message on position 0 is for the current device

    if(lastFiredliveMessages?.deviceId && lastFiredliveMessages?.deviceId === deviceId){
      if(lastFiredliveMessages){
        if(liveMessages===undefined){
          setLiveMessages([lastFiredliveMessages])
        }
        else{
          // storing the last 100 messages
          setLiveMessages([lastFiredliveMessages, ...liveMessages].slice(0, 100))
        }
      }
    }
  }, [lastFiredliveMessages]);// eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if(liveMessages !== undefined){
      if(liveMessages.length > 0 && (liveMessages[0].deviceId === deviceId) ){

        // its required that the message on position 0 is for the current device
        if(liveMessages[0].body?.name === "deviceDoorStateChanged" ){

            if(props.installation?.formData?.Number_Of_Doors__c === 2 && isValidDoorConfiguration([props.installation?.formData?.Car_Door_1_Direction__c, props.installation?.formData?.Car_Door_2_Direction__c]))
            {
              // two doors
              if(props.installation?.formData?.Car_Door_1_Direction__c === 'North'){ // Door 1 = North / Door 2 = South
                // door 1
                if(liveMessages[0].body.attributes.doors[0].doorState === "doorClosed"){
                  setDoorNorthOpen(false)
                }
                else if(liveMessages[0].body.attributes.doors[0].doorState === "doorOpened"){
                  setDoorNorthOpen(true)
                }

                // door 2
                if(liveMessages[0].body.attributes.doors[1].doorState === "doorClosed"){
                  setDoorSouthOpen(false)
                }
                else if(liveMessages[0].body.attributes.doors[1].doorState === "doorOpened"){
                  setDoorSouthOpen(true)
                }

              }
              else{ // Door 1 = South / Door 2 = North

                // door 1
                if(liveMessages[0].body.attributes.doors[0].doorState === "doorClosed"){
                  setDoorSouthOpen(false)
                }
                else if(liveMessages[0].body.attributes.doors[0].doorState === "doorOpened"){
                  setDoorSouthOpen(true)
                }

                // door 2
                if(liveMessages[0].body.attributes.doors[1].doorState === "doorClosed"){
                  setDoorNorthOpen(false)
                }
                else if(liveMessages[0].body.attributes.doors[1].doorState === "doorOpened"){
                  setDoorNorthOpen(true)
                }
              }           
            }
            else{
              if(liveMessages[0].body.attributes.doors[0].doorState === "doorClosed"){
                setDoorSouthOpen(false)
              }
              else if(liveMessages[0].body.attributes.doors[0].doorState === "doorOpened"){
                setDoorSouthOpen(true)
              }
            }
        }
      }
    }

}, [liveMessages]);// eslint-disable-line react-hooks/exhaustive-deps

  const onChangeDatePicker = (dates: any, dateStrings: any) => {
    setFrom(dates[0].startOf('day').utc().format())
    setTo(dates[1].endOf('day').utc().format())
  }

return (
    <div className=" bg-white pt-8 pl-8 pr-8 pb-16 IoT  text-base">
      <div className="flex  border-b border-blueDark pb-10 pt-5 flex-col m:flex-row ">
        <div className={mqttConnectionLost 
        ? "flex border-0 border-blueDark w-full m:w-3/12 pr-14 m:border-r pb-14 m:pb-0 m:border-b-0"
        : "flex border-0 border-blueDark w-full m:w-3/12 pr-14 m:border-r pb-14 m:pb-0 m:border-b-0  border-b" }>
          <Status device={ioTDevice} isLoading={isLoadingPastEvents} />
        </div>
        { (false)?
          <div className="hidden border-r border-blueDark pr-14 pl-14 pb-10">
            <Availibility device={ioTDevice} isLoading={isLoadingPastEvents} />
          </div>
          :<></>
        }
        <div className={mqttConnectionLost ? " mt-14 m:mt-0 m:pr-14 m:pl-14 pb-10 hidden" : " mt-14 m:mt-0 m:pr-14 m:pl-14 pb-10" }>
          <LevelVisualization initialData={pastEvents} device={ioTDevice} liveMessages={liveMessages} isLoading={isLoadingPastEvents} />
        </div>
      </div>
      
      <div className=" sticky top-0 z-10 flex p-5 pl-0 pr-0 bg-white mb-14 border-b border-greyLighter stickyContainer">
        <div className=" pr-5 font-bold text-lg">Aufzuganalyse:</div> 
        <RangePicker
          className=" mr-5"
          onChange={onChangeDatePicker}
          defaultValue={[moment(from),moment(to)]}
          allowClear={false}
          //renderExtraFooter={() => 'extra footer'}
          ranges={{
            'heute': [moment(), moment()],
            'aktueller Monat': [moment().startOf('month'), moment().endOf('month')],
            'letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'letzte 7 Tage': [moment().subtract(7, 'days'), moment()],
            'letzte 14 Tage': [moment().subtract(14, 'days'), moment()],
            'letzte 30 Tage': [moment().subtract(30, 'days'), moment()],
            'letzte 60 Tage': [moment().subtract(60, 'days'), moment()],
        }} />
      </div>
      <div className="flex border-b border-blueDark pb-10 mb-10 flex-col l:flex-row">
        <div className="flex flex-col m:flex-row  w-full l:w-6/12 b-0 m:border-b l:border-b-0 pb-0 m:pb-10 l:pb-0">
          <div className="flex flex-col pb-10 m:pb-0 pr-0 m:pr-14 border-r-0 m:border-r border-b m:border-b-0 border-blueDark w-full m:w-1/2 l:w-1/2">
            <GeneralStatistic runsPerFloor={runsPerFloor} doorCyclesCount={doorCyclesCount} isLoading={(isLoadingDoorCyclesCount || isLoadingRunsPerFloor)} />
          </div>
          <div className="border-r-0 l:border-r border-b m:border-b-0 border-blueDark pr-0 m:pr-14 pl-0 m:pl-14 pb-14 m:pb-0 pt-10 m:pt-0 w-full  m:w-1/2 l:w-1/2">
            <UsageChartMemo  data={generalStatistic} isLoading={isLoadingDuration} />
          </div>
        </div>
        <div className=" pt-10 l:pt-0 pb-10 m:pb-0 pr-0 l:pr-14 pl-0 l:pl-14 pb-0 flex flex-col l:flex-row w-full l:w-6/12">
          <div className=" w-full l:w-4/12">
            <div className="font-bold mb-4">Aufzug-Zustand</div>
            {  !(props.installation?.formData?.Number_Of_Doors__c === 2 && isValidDoorConfiguration([props.installation?.formData?.Car_Door_1_Direction__c, props.installation?.formData?.Car_Door_2_Direction__c])) ? <>
              <div>{doorSouthOpen ? 'Türen geöffnet' : 'Türen geschlossen'}</div>
            </> : <></>
            }
          </div>
          <div className=" w-full l:w-8/12 flex justify-center pl-5 pr-5 l:pl-0 l:pr-0">
            { (props.installation?.formData?.Number_Of_Doors__c === 2
               && isValidDoorConfiguration([props.installation?.formData?.Car_Door_1_Direction__c, props.installation?.formData?.Car_Door_2_Direction__c])
               ) ? <DoorNorthSouth southIsOpen={doorSouthOpen} northIsOpen={doorNorthOpen} />  : <DoorSouth southIsOpen={doorSouthOpen} /> }
          </div>
        </div>
      </div>
      
      <div className=" border-b border-blueDark pb-0 mb-10">
        <div key="headline" className=" text-lg font-bold">Fahrten über Zeitraum</div>
        <TurnsChartMemo data={runsOverTime} isLoading={isLoadingRunsOverTime}  />
      </div>

      <div className=" pb-0 mb-10">
        <div className=" text-lg font-bold">Fahrten zwischen Haltstellen</div>
        <TurnsBetweenLevelsChartMemo data={runsBetweenAllFloors} device={ioTDevice} isLoading={isLoadingRunsBetweenAllFloors || isLoadingDecice} />
      </div>
    </div>
  );
}
export default IoT;

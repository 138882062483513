import ReactEChartsCore from 'echarts-for-react/lib/core';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import { Spin } from 'antd';
import { isDoorActivityAvailable } from '../../../utils/helperIoT';


// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
  PieChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import React, { useEffect, useState } from 'react';


//I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
//I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function UsageChart(props:{data:any,isLoading:boolean}) {

  echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, CanvasRenderer, LegendComponent, PieChart]
  );
  
  echarts.registerTheme('light', {
    backgroundColor: '#fff'
  });

  const [chartData, setChartdata] = useState<{value:any,name:string}[]>([])

  useEffect(() => {
    
    let tempChartData = []
    // door_closings_duration
    // door_openings_duration
    // door_opened_duration
    
    if(props.data.door_closings_duration){
      tempChartData.push({value:props.data.door_closings_duration, name:  'Schliessend'})
//      tempChartData.push({value:props.data.door_closings_duration, name:  moment.utc(props.data.door_closings_duration).format('HH:mm:ss' ) + ' Schliessend'})
    }
    if(props.data.runs_duration){
      tempChartData.push({value:props.data.door_openings_duration, name: 'Öffnend'})
//      tempChartData.push({value:props.data.door_openings_duration, name: moment.utc(props.data.door_openings_duration).format('HH:mm:ss' ) + ' Öffnend'})
    }
    if(props.data.door_cycles_duration){
      tempChartData.push({value:props.data.door_opened_duration, name: 'Offen'})
//      tempChartData.push({value:props.data.door_opened_duration, name: moment.utc(props.data.door_opened_duration).format('HH:mm:ss' ) + ' Offen'})
    }
    setChartdata(tempChartData)
  }, [props.data]);

  var optionPie = {
    color:[
      '#88E0FE',
      '#00265E',
      '#05ADF5'
    ],
    colorBy: 'series',
    tooltip: {
      trigger: 'item',
      formatter: function (params: any, ticket: any, callback: any) {
      return moment.utc(params.data.value).format('HH:mm:ss' ) 
    }
    },
    legend: {
      orient: 'vertical',
      left: 'right'
    },
    series: [
      {
        type: 'pie',
        radius: '90%',
        label: { show: false },
        labelLine: { show: false },
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
/*
  const onChartClick = () => {
    console.log('click')
  }

  const onChartReadyCallback = () => {
    console.log('onChartReadyCallback')
  }

  const onChartLegendselectchanged = () => {
    console.log('onChartLegendselectchanged')
  }

  const onEvents = {
    'click': onChartClick,
    'legendselectchanged': onChartLegendselectchanged
  }
*/
  return (
    <>

    {isDoorActivityAvailable(props.data) ? <Spin size="large" spinning={props.isLoading}>
      <div className="font-bold mb-4">Türaktivität</div>
      <ReactEChartsCore
      echarts={echarts}
      option={optionPie}
      notMerge={false}
      lazyUpdate={true}
      theme={"light"}
      showLoading={false}
      style={{height: '200px', width: '100%'}}
      //onChartReady={onChartReadyCallback}
      //onEvents={onEvents}
      //opts={}
      />
    </Spin>  : <><div style={{'height':'300px'}}><div className="font-bold mb-4">Türaktivität</div><div className="m-auto">keine Daten für den gewählten Zeitraum</div></div></>}
    </>

  );
}
export default UsageChart;

import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
  LineChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import { useEffect, useState } from 'react';

function TurnsChart(props:{data:any,isLoading:boolean}) {

  const [noData, setNoData] = useState(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {

      let dataTmp = []
      for (let key in props.data) {
        dataTmp.push([props.data[key].time,props.data[key].value])
      }

      setData(dataTmp)

      if(dataTmp.length > 0){
        setNoData(false)
      }
      else{
        setNoData(true)
      }

  }, [props.data]);

  echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, CanvasRenderer, LegendComponent, LineChart,ToolboxComponent,DataZoomComponent]
  );
  
  echarts.registerTheme('light', {
    backgroundColor: '#fff'
  });

  let option = {
    grid:{
      left:'1%',
      right:'1%',
      containLabel:true
    },
    tooltip: {
      trigger: 'axis',
      position: function (pt: any[]) {
        return [pt[0], '10%'];
      }
    },
    toolbox: {
      feature: {
        /*
        dataZoom: {
          yAxisIndex: 'none'
        },
        saveAsImage: {}
        */
      }
    },
    xAxis: {
      type: 'time',
      boundaryGap: false
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%']
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        type: 'line',
        smooth: false,
        symbol: 'none',
        areaStyle: {},
        data: data
      }
    ]
  };

  return (
    <>
    {noData && <div className="flex" style={{'height':'300px'}}><div className="m-auto">keine Daten für den gewählten Zeitraum</div></div>}
    {!noData && 
      <Spin size="large" spinning={props.isLoading} key="spin">
        <ReactEChartsCore
          key="chartTurnchart"
          echarts={echarts}
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={"light"}
          showLoading={false}
          style={{height: '300px', width: '100%'}}
        />
      </Spin>
    }
    </>
  );
}
export default TurnsChart;

import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../utils/globalLocalization';
import { strings as stringLocal } from './InstallationsLocalization';
import { USER_CHOICE_LANGUAGE } from './../utils/constants';
import { mergeDeepRight } from 'ramda';
import { Button, Popover, Table } from 'antd'
import { useGlobalState, GlobalStateInterface } from "../utils/globlStateProvider";
import { API, GraphQLResult } from '@aws-amplify/api'
import { MaintenanceContractStatus, Query, SmartMaintenanceContractPage } from '../graphql/API';
import * as queries from './../graphql/queries';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from './Tables/useWindowDimensions';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as uuid from 'uuid';
//import { promiseLoadContracts } from '../utils/helpers';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
I18n.setLanguage(lang)
moment.locale(lang? lang: 'de') ;

function Installations() {

  const { state, setState } = useGlobalState()
  // eslint-disable-next-line 
  const { height, width } = useWindowDimensions();// eslint-disable-this-line no-unused-vars
  const [filteredInfo, setFilteredInfo] = React.useState({city:'',zip:''})
  const [sortedInfo, setSortedInfo] = React.useState({columnKey:'',order:''})
  let history = useHistory();
  

  const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = React.useRef<T>();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevState = usePrevious({filteredInfo});

  React.useEffect(() => {
    if(JSON.stringify(filteredInfo.city)!==JSON.stringify(prevState?.filteredInfo.city)){
      // clear zip filter if city changes
      setFilteredInfo({city:filteredInfo.city,zip:''})
    }
  }, [ filteredInfo ]);// eslint-disable-line react-hooks/exhaustive-deps

  const loadContracts = async() => {
    const res = await (API.graphql({ query: queries.contracts , variables:{ page:{}, filter:{} }}) as GraphQLResult<Query> );
    if(res.data?.contracts?.items != null){
      let dataGlobal:Partial<GlobalStateInterface> = {
        contracts: res.data?.contracts as SmartMaintenanceContractPage,
        contractsAreLoaded:true
      } 
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }
  if(!state.contractsAreLoaded){ loadContracts() }

  /*
  if(!state.contractsAreLoaded && !state.contracts?.before){ 
    promiseLoadContracts.then( ( dataGlobal ) => {
      if(dataGlobal && typeof dataGlobal === 'object'){
        setState((prev) => ({ ...prev, ...dataGlobal }));
      }
    })
  }
*/
  const dataSource:any = []
  let cities:string[] = []

  state.contracts?.items?.forEach( itemContract => {
    let arrValidContractStates: MaintenanceContractStatus[] = ['ReadyToAccept','Accepted']
    if(itemContract?.status 
      && arrValidContractStates.includes(itemContract?.status) ){
        itemContract?.installations?.forEach( itemInstallation => {
          if(itemInstallation?.address && itemInstallation?.formData){
            //console.log(itemInstallation?.formData,itemInstallation)
            let objInstallationData = itemInstallation?.formData

            // fill city selector
            if(cities.indexOf(itemInstallation.address.city) < 0){
              cities.push(itemInstallation.address.city)
            }
            
            dataSource.push({
              key: itemInstallation._id + uuid.v4(),
              urlKey: itemInstallation._id,
              name: itemInstallation.titleByCustomer? itemInstallation.titleByCustomer : itemInstallation.serialNumber,
              city: itemInstallation.address.city,
              address: itemInstallation.address.street,
              zip: itemInstallation.address.postalCode,
              zueslast: objInstallationData.Last_Date_Of_Main_Audit__c? moment(objInstallationData.Last_Date_Of_Main_Audit__c).format('L'): '-',
              zuesnext: objInstallationData.Next_Date_Of_Main_Audit__c? moment(objInstallationData.Next_Date_Of_Main_Audit__c).format('L'): '-',
              maintlast: objInstallationData.Last_Date_Of_Maintenance__c ? moment(objInstallationData.Last_Date_Of_Maintenance__c).format('L') : '-',
              maintnext: objInstallationData.Next_Date_Of_Maintenance__c ? moment(objInstallationData.Next_Date_Of_Maintenance__c).format('L') : '-',
            })
          }
        })
      }
  })

  let cityFilter:any[] = []
  cities.sort();
  cities.forEach(item => {
    cityFilter.push({text:item,value:item})
  })

  const getZipFilterOptions = () => {

    let zipCodes:string[] = []
    state.contracts?.items?.forEach( itemContract => {
      itemContract?.installations?.forEach( itemInstallation => {
        if(itemInstallation?.address){
          if( zipCodes.indexOf(itemInstallation.address.postalCode) < 0){
              if(filteredInfo.city?.length > 0){
                // options filtered by city
                if(filteredInfo.city.indexOf(itemInstallation.address.city) > -1){
                  zipCodes.push(itemInstallation.address.postalCode)
                }
              } 
              else{
                zipCodes.push(itemInstallation.address.postalCode)
              }
          }  
        }
      })
    })    
 
    let zipFilter:any[] = []
    zipCodes.sort();
    zipCodes.forEach(item => {
      zipFilter.push({text:item,value:item})
    })

    return zipFilter
  }

  const responsiveBreakpoint = 1000

  let fixed = {}
  let normalWidth = {}
  let nameWidth = {}
  let adressWidth = {}
  let plzWidth = {}
  let datesWidth = {}
  let labelName = 'AUFZUGNUMMER'

  if(width < responsiveBreakpoint){
    fixed = {fixed: 'left'}
    normalWidth = {width: 150}
    nameWidth = {width: 100}
    plzWidth = {width: 100}
    adressWidth = {width: 200}
    datesWidth = {width : 100}
    labelName = 'AUFZUG'
  }

  const columns:any = [
    {
      title: labelName,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      ...fixed,
      ...nameWidth
    },
    {
      title: 'Stadt',
      dataIndex: 'city',
      key: 'city',
      filters: cityFilter,
      filteredValue: filteredInfo?.city || null,
      onFilter: (value: any, record: { city: string; }) => record.city.indexOf(value) === 0,
      sorter:  (a: { city: string ; }, b: { city: string ; }) => a.city.localeCompare(b.city),
      sortOrder: sortedInfo.columnKey === 'city' && sortedInfo.order,
      ...normalWidth
    },
    {
      title: 'PLZ',
      dataIndex: 'zip',
      key: 'zip',
      filters: getZipFilterOptions(),
      filteredValue: filteredInfo?.zip || null,
      onFilter: (value: any, record: { zip: string; }) => record.zip.indexOf(value) === 0,
      ...plzWidth
    },
    {
      title: 'ADRESSE',
      dataIndex: 'address',
      key: 'address',
      sorter:  (a: { address: string ; }, b: { address: string ; }) => a.address.localeCompare(b.address),
      sortOrder: sortedInfo.columnKey === 'address' && sortedInfo.order,
      ...adressWidth
    },
    {
      title: 'LETZTE ZÜS-PRÜFUNG',
      dataIndex: 'zueslast',
      key: 'zueslast',
      ...datesWidth
    },
    {
      title: 'NÄCHSTE ZÜS-PRÜFUNG',
      dataIndex: 'zuesnext',
      key: 'zuesnext',
    },
    {
      title: 'LETZTE WARTUNG',
      dataIndex: 'maintlast',
      key: 'maintlast',
      ...datesWidth
    },
    {
      title: 'NÄCHSTE WARTUNG',
      dataIndex: 'maintnext',
      key: 'maintnext',
    },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    //console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  };

  const clearAll = () => {
    setFilteredInfo({city:'',zip:''})
    setSortedInfo({columnKey:'',order:''})
  };

  const isFilterButtonDisabled=()=>{
    if(filteredInfo.city?.length > 0 || filteredInfo.zip?.length > 0){
      return false
    }
    else{
      return true
    }
  }

  const onRowClicked = (record: any) => {
    history.push('/installations/'+record.urlKey)
  }

  const popOvercontent = (
    <div>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    </div>
  );

  return (
    <section className="Content bg-greyLighter">
      <div>
        <h3 className="pb-9 flex place-items-start">
          {I18n.get('titleInstallations')}
          <Popover 
            placement="bottom"
            content={popOvercontent}
            title="Meine Anlagen"
            trigger="click"
            className="pl-2 hidden"
            overlayClassName="headlinePopover"
            >
            <InfoCircleOutlined style={{ fontSize:16}}/>
          </Popover>
        </h3>
        <div className="subPageContentContainer">
          <div className="innerElement"><Button disabled={isFilterButtonDisabled()} onClick={clearAll}>Filter zurücksetzen</Button></div>
          <br/>
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'showMousePointer' :  'bg-greyLighter showMousePointer'}
            dataSource={dataSource}
            columns={columns}
            onChange={handleChange}
            loading={!state.contractsAreLoaded}
            onRow={(record, rowIndex) => {
              return {
                  onClick: event => onRowClicked(record), // click row
                  onDoubleClick: event => {}, // double click row
                  onContextMenu: event => {}, // right button click row
                  onMouseEnter: event => {}, // mouse enter row
                  onMouseLeave: event => {}, // mouse leave row
              };
              }}
            scroll={{ x: responsiveBreakpoint }}
            sticky
              
              />
        </div>
      </div>
    </section>
  );
}

export default Installations;

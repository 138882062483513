import Tile from './index'
import icon from './../../assets/images/icons/map.svg'
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { useGlobalState,  } from "../../utils/globlStateProvider";
import React, { useEffect, useRef } from 'react';
import { EnvironmentTwoTone } from '@ant-design/icons';
import { Spin } from 'antd';
import mapStyles from "./TileMapMapStyles"
import "./TileMap.css"
import { MaintenanceContractStatus } from '../../graphql/API';
import { Link } from 'react-router-dom';
import * as uuid from 'uuid';

function TileMap() {
  const { state } = useGlobalState();
  var headline = 'Anlagen auf der Karte'
  var mapsApiCode = 'AIzaSyDPpxzfV8E4nubAporwnJgJ82DPZPRTGmk'

  Geocode.setApiKey(mapsApiCode);
  Geocode.setLanguage("en")

  type objMarker = { lat:Number,lng:Number,name:string ,key:string, show:boolean, needToLoadLatLng:boolean,label:string};
  const [markers, setMarkers] = React.useState<objMarker[]>([])
  const [markersToLoadLatLng, setMarkersToLoadLatLng] = React.useState<objMarker[]>([])
  const [geoCoordiantesLoaded, setGeoCoordiantesLoaded] = React.useState<boolean>(false)


  useEffect(() => {
    
    markersToLoadLatLng.forEach((place) => {
      Geocode.fromAddress(place.label).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          
          let updateMarkers = [{} as objMarker]
          updateMarkers = []
          markers.forEach((placeMarkers) => {
            if(placeMarkers.key === place.key){
              placeMarkers.lat = lat
              placeMarkers.lng = lng
              placeMarkers.needToLoadLatLng = false
              updateMarkers.push(placeMarkers)  
            }
            else{
              updateMarkers.push(placeMarkers)  
            }
          });
          setMarkers(updateMarkers)
        },
        (error) => {
          console.error(error);
        }
      ); 

    });

    //setGeoCoordiantesLoaded(true)       
  }, [ markersToLoadLatLng ]);// eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    
    let allLoaded = true
    if(markers.length <= 0){
      return 
    }

    markers.forEach((place) => {
      if(place.needToLoadLatLng){
        allLoaded = false 
      }
    });
    
    if(allLoaded){
      setGeoCoordiantesLoaded(true)
    }
  }, [ markers ]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let places = [{} as objMarker]
    places = []
    let placesToLoadLatLng = [{} as objMarker]
    placesToLoadLatLng = []


    let arrValidContractStates: MaintenanceContractStatus[] = ['Accepted','InPreview','ReadyToAccept']
    state.contracts?.items?.forEach(contract => {
      if(contract?.status 
        && arrValidContractStates.includes(contract?.status) ){
          contract?.installations?.forEach(installation => {
            if(installation?.address?.coordinates?.lat){
              let tmpObj = {} as objMarker
              tmpObj.key = installation._id
              tmpObj.lat = installation.address.coordinates.lat
              tmpObj.lng = installation.address.coordinates.lng
              tmpObj.name = installation?.label || ''
              tmpObj.show = false
              tmpObj.needToLoadLatLng = false
              tmpObj.label = installation?.address?.label
              places.push(tmpObj)
            }
            else if(installation?.address?.label){
              let tmpObj = {} as objMarker
              tmpObj.key = installation._id
              tmpObj.lat = 0
              tmpObj.lng = 0
              tmpObj.name = installation?.label || ''
              tmpObj.show = false
              tmpObj.needToLoadLatLng = true
              tmpObj.label = installation?.address?.label
              places.push(tmpObj)
              placesToLoadLatLng.push(tmpObj)
            }            
          })
      }
      setMarkers(places)
      setMarkersToLoadLatLng(placesToLoadLatLng)
    });       
  }, [ state.contractsAreLoaded ]);// eslint-disable-line react-hooks/exhaustive-deps

  const showInfowindow = (id:any) =>{
    const index = markers.findIndex((e) => e.key === id);
    let myMarkers =  JSON.parse(JSON.stringify( markers ))
    console.log(myMarkers)
    myMarkers.forEach((element: { show: boolean; }) => {
      element.show = false
    });
    myMarkers[index].show = !markers[index].show
    setMarkers(myMarkers)
  }


  const MapMarker = ({ text,show,id }:any ) => {
    const markerRef = useRef(null);
    return (
      <div className="mapMarker"  ref={markerRef}>
        <EnvironmentTwoTone onClick={()=>{ showInfowindow(id)}} alt={text} twoToneColor="#34495E" style={{ fontSize: '26px' }}/>
        {show && <div className="googleMapsInfoWindow"><Link to={'/installations/'+id}>{text}</Link></div>}
      </div>
      )
    };

  // Re-center map when resizing the window
  const bindResizeListener = (map: any, maps: { Marker?: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; event?: any; }, bounds: any,markers:any) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
        if(markers.length <= 1){
          map.setZoom(15);
        }
      });
    });
  };

  // Return map bounds based on list of places
  const getMapBounds = (map: any, maps: { Marker?: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; LatLngBounds?: any; LatLng?: any; }) => {
    const bounds = new maps.LatLngBounds();
    markers.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  const centerMap = (map: any, maps: { Marker: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; },markers: { lat: Number; lng: Number; name: string; key: string; }[]) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps);
    // Fit map to bounds
    map.fitBounds(bounds);
    if(markers.length <= 1){
      map.setZoom(15);
    }
    // Bind the resize listener
    bindResizeListener(map, maps, bounds,markers);
   return [];
  };

  const getMap = () => {
    return <GoogleMapReact
      bootstrapURLKeys={{ key: mapsApiCode}}
      defaultCenter={{lat:59.95,lng:30.33}}
      defaultZoom={11}
      yesIWantToUseGoogleMapApiInternals
      options={{styles:mapStyles}}
      onGoogleApiLoaded={({map, maps}) => centerMap(map, maps, markers)}
      >
      {markers.map((place) => (
          <MapMarker
            key={place.key + '-' + uuid.v4()}
            id={place.key}
            text={place.name}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
          />
        ))}
      </GoogleMapReact>
    }

    var content = <div className={state.contractsAreLoaded? " p-3" : " p-3 pl-16"} style={{ height: '265px', width: '100%' }}>{(state.contractsAreLoaded && geoCoordiantesLoaded)? getMap() : <Spin size="large" /> }</div>
    return (
        <Tile icon={icon} headline={headline} content={content} />
    );
  }
  
export default TileMap;
  
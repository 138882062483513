import { Select } from 'antd'
import { DocumentRef, Installation, MaintenanceContractStatus } from '../../graphql/API';
import { useGlobalState } from "../../utils/globlStateProvider";
import { DocumentsType } from './Types'
import { Storage } from 'aws-amplify';
import { useState} from 'react';
import { getInstallationDbIdsByDocument, isPhoto, openDocument } from '../../utils/helpers';
import { Radio } from 'antd'
import { useEffect } from 'react';
import { FileSearchOutlined,DownloadOutlined } from '@ant-design/icons'
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

function AhTable(props:{documents:DocumentsType | undefined,contractsAreLoaded?:boolean, isSingleInstalation?:boolean ,singleInstallation?:Installation,scrollPosition: any}){
    const { state } = useGlobalState()
    const [pictures, setPictures] = useState<any[]>([])
    const [typeFilter, setTypeFilter] = useState('all')
    const [installation, setInstallation] = useState<String | undefined>(undefined)

    useEffect(() => {
        if(props.isSingleInstalation && props.singleInstallation){
            setInstallation(props.singleInstallation._id)
        }
    }
  , [props.singleInstallation]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
            var photos: DocumentRef[] = []
            // display all photos
            props.documents?.photos?.forEach(itemDocument => {
                if(itemDocument?.form && isPhoto(itemDocument.form)){
                    if(installation && itemDocument.form.selectedInstallations.includes(installation.toString())){
                        // category filter
                        if(typeFilter === 'notAStandardCategory'){
                            if(isOtherPhoto(itemDocument)){
                                photos.push(itemDocument)
                            }
                        }
                        else if(typeFilter === 'all' || itemDocument.form.categories.includes(typeFilter)){
                            photos.push(itemDocument)
                        }

                    }
                }
            })
            setPhotos(photos)
        }
      , [installation,typeFilter,props.singleInstallation]);// eslint-disable-line react-hooks/exhaustive-deps

    const isOtherPhoto = (itemDocument:DocumentRef) => {
        let isOtherPhoto = false
        if(  isPhoto(itemDocument.form) ){
            let knownPhotoCategories = ['pc-door','pc-shaft','pc-machine-room','pc-type-sign','pc-cabin','pc-control-element']
            itemDocument.form.categories.forEach( ( category ) => {
                console.log(knownPhotoCategories.includes(category),knownPhotoCategories,category)
                if(knownPhotoCategories.includes(category) === false){
                    isOtherPhoto = true
                }
            })
        }
        return isOtherPhoto
    }

    const getFilter=()=>{
        let buttonOthers = <></>
        if(props.documents && props.documents?.photos.length > 0){
            props.documents?.photos.forEach((document) => {
                if(getInstallationDbIdsByDocument(document,state.contracts)?.includes(installation) && isOtherPhoto(document)){
                    buttonOthers = <Radio.Button value="notAStandardCategory">Andere</Radio.Button>
                }
            })
        }

        return (
          <div>
            <Radio.Group disabled={installation?false:true} value={typeFilter}  onChange={(e) => { setTypeFilter(e.target.value) }} >
                <Radio.Button value="all">Alle</Radio.Button>
                <Radio.Button value="pc-drive">Antrieb</Radio.Button>
                <Radio.Button value="pc-control-element">Bedienelement</Radio.Button>
                <Radio.Button value="pc-cabin">Kabine</Radio.Button>
                <Radio.Button value="pc-machine-room">Maschinenraum</Radio.Button>
                <Radio.Button value="pc-shaft">Schaft</Radio.Button>
                <Radio.Button value="pc-control-cabinet">Schaltschrank</Radio.Button>
                <Radio.Button value="pc-door">Tür</Radio.Button>
                <Radio.Button value="pc-type-sign">Typenschild</Radio.Button>
                {buttonOthers}
            </Radio.Group>
          </div>
          )
      }

    async function setPhotos(documents: DocumentRef[]){
        setPictures([])
        documents.forEach( elem => {
            if(elem.mimeType === 'image/jpeg'
            || elem.mimeType === 'image/png'){
                // org   documents/uploaded/contractID/*
                // thumb thumbnails/300/uploaded/contractID/*
                setLoadedPicture(elem.s3Key.replace('documents/','thumbnails/300/'),elem,true)
            }
            else{
                setLoadedPicture(elem.s3Key,elem)
            }
        })
    }

    const setLoadedPicture = (s3KeyToLoad: string, elem: DocumentRef,loadOriginalFileIfThumbailLoadFailes:boolean = false) => {
        Storage.get(s3KeyToLoad)
        .then(url => {
            let media = <></>
            if(    elem.mimeType.indexOf("video") > -1 ){
                media = <video controls preload="auto">
                        <source src={url.toString()} />
                        Your browser does not support the video tag.
                    </video>
                
            }
            else{
                media =  <LazyLoadImage
                    alt={elem.originalFileName}
                    src={url.toString()} // use normal <img> attributes as props                            
                    effect="black-and-white"
                    scrollPosition={props.scrollPosition}                            
                    />
            }

            let imgWrap = <span key={elem.s3Key} className={"img " + elem.mimeType.replace('/','').replace('+','')} >
                {media}
                <div className="tooltip text-center">
                    <FileSearchOutlined onClick={()=>openDocument({key:elem.s3Key,originalFileName:elem.originalFileName},false)} className="pr-4" style={{ fontSize: '26px', color: '#08c' }} />
                    <DownloadOutlined onClick={()=>openDocument({key:elem.s3Key,originalFileName:elem.originalFileName},true)} className="pr-4" style={{ fontSize: '26px', color: '#08c' }} />
                </div>
            </span>

            setPictures((pictures) => [...pictures,imgWrap])
        })
        .catch(err => {
            console.log(err)
            if(loadOriginalFileIfThumbailLoadFailes){
                setLoadedPicture(elem.s3Key,elem,false)
            }
        });
    }

    const getInstallations = () => {
        var options:any = []
        let arrValidContractStates: MaintenanceContractStatus[] = ['ReadyToAccept','Accepted']
        state.contracts?.items?.forEach( itemContract => {
            if(itemContract?.status 
                && arrValidContractStates.includes(itemContract?.status) ){
                    itemContract?.installations?.forEach( itemInstallation => {
                        let value = itemInstallation?.address.city + ' - ' + itemInstallation?.address.street + ' - ' + itemInstallation?.serialNumber
                        options.push({value:value,id:itemInstallation?._id})
                    })
                }
        })
        options.sort((a: { value: string; }, b: { value: string; }) => (a.value < b.value ? -1 : 1));
        return options.map((elem:any)=>{
            return <Option key={elem.id} value={elem.id}>{elem.value}</Option>
        })
    }

    const { Option } = Select;

    return <>
        <div className={ props.isSingleInstalation?'innerElement pl-8 pr-8':'innerElement' }>
            {!props.isSingleInstalation && <>
                <Select
                showSearch
                style={{ width: 400 }}
                placeholder="Anlage wählen"
                optionFilterProp="children"
                onSelect={(elem)=>{ setInstallation(elem?.toString())}}
                filterOption={(input, option) => {
                    return option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                }
                filterSort={(elemA, elemB) => {
                    return elemA.children.toString().toLowerCase().localeCompare(elemB.children.toString().toLowerCase())
                    }
                }
                >
                    {getInstallations()}
                </Select>
                <br/><br/>
                </>
            }
            {getFilter()}<br/><br/>
            <div className="gallery text-center">{pictures}</div>
            {!installation ? <div className="hint">Bitte wählen Sie ein Anlage aus!</div> : <></> }
            {(installation && pictures.length <= 0) ? <div className="hint">Es sind keine Bilder vorhanden!</div> : <></> }
        </div>
        </>
}

export default trackWindowScroll(AhTable);
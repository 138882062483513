import ReactDOM from 'react-dom';
import App from './routes/Routes';
import reportWebVitals from './reportWebVitals';
import Amplify from "aws-amplify";
import awsconfig  from "./config";
import "antd/dist/antd.css";
import './index.css';

Amplify.configure(awsconfig);

ReactDOM.render(<App />,document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLocal } from './../DocumentsLocalization';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import { mergeDeepRight } from 'ramda';
import { Button } from 'antd'
import { useGlobalState } from "../../utils/globlStateProvider";
import { Installation} from '../../graphql/API';
import React, { useEffect } from 'react';
import TableContracts from './../Tables/DocumentsTableContracts'
import TableBills from './../Tables/DocumentsTableBills'
import TableInspectionCertificates from './../Tables/DocumentsTableInspectionCertificates'
import TableManuals from './../Tables/DocumentsTableManuals'
import TableLogs from './../Tables/DocumentsTableLogs'
import TableDrawings from './../Tables/DocumentsTableDrawings'
import TablePhotos from './../Tables/DocumentsTablePhotos'
import TableOthers from './../Tables/DocumentsTableOthers'
import {DocumentsType} from './../Tables/Types'
import './Documents.css'
import { getContractByInstallation, isBill, isContract, isDocument, isPhoto } from '../../utils/helpers';
import { useLocation } from 'react-router-dom';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Documents(props:{installation:Installation | undefined}) {
  
  const { state } = useGlobalState()
  const [typeFilter, setTypeFilter] = React.useState('contracts')
  const tmpDocuments:DocumentsType = {contracts:[],bills:[],inspectionCertificats:[],manuals:[],logbooks:[],technicaDrawings:[],others:[],photos:[]}
  const location = useLocation();

  const [documents, setDocuments] =React.useState<DocumentsType | undefined>();

  useEffect(() => {
    let contract = getContractByInstallation(props.installation,state.contracts)

    contract?.documents?.forEach( itemDocument => {
      if(itemDocument?.form){
        //console.log(itemDocument.form)

        if(itemDocument.form.isInternal){
          // dont show internal files
          // with the new api this needs to get handled by the api
        }else if(  isContract(itemDocument.form) ){
          tmpDocuments.contracts.push(itemDocument)
        }
        else if(  isBill(itemDocument.form) ){
          tmpDocuments.bills.push(itemDocument)
        }
        else if(  isPhoto(itemDocument.form) ){
          tmpDocuments.photos.push(itemDocument)
        }
        else if(  isDocument(itemDocument.form) ){
          // dc-manual | dc-photo | dc-technical-drawing
          // collect by categorie , if others left than the above ones than collect also to "others"
          var index = 0
          let categories = [...itemDocument.form.categories]
          if(categories.includes("dc-technical-drawing")){
            tmpDocuments.technicaDrawings.push(itemDocument)
            index = categories.indexOf("dc-technical-drawing");
            if (index !== -1) {
              categories.splice(index, 1);
            }
          }
          if(categories.includes("dc-manual")){
            tmpDocuments.manuals.push(itemDocument)
            index = categories.indexOf("dc-manual");
            if (index !== -1) {
              categories.splice(index, 1);
            }
          }
          if(categories.includes("dc-log")){
            tmpDocuments.logbooks.push(itemDocument)
            index = categories.indexOf("dc-log");
            if (index !== -1) {
              categories.splice(index, 1);
            }
          }
          if(categories.includes("dc-inspection-certificate")){
            tmpDocuments.inspectionCertificats.push(itemDocument)
            index = categories.indexOf("dc-inspection-certificate");
            if (index !== -1) {
              categories.splice(index, 1);
            }
          }
          if(categories.length > 0){
            tmpDocuments.others.push(itemDocument)
          }          
        }
      }
      setDocuments(tmpDocuments)
    })

  }, [props.installation]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set category onload
    if(location.pathname === '/documents/bills'){
      setTypeFilter('bills')
    }
    else if(location.pathname === '/documents/contracts'){
      setTypeFilter('contracts')
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const getFilter=()=>{
    
    let buttonOthers = <></>
    if(documents && documents?.others.length > 0){
      buttonOthers = <Button type={typeFilter === 'notAStandardCategory'? "primary" : "default"} onClick={()=>setTypeFilter('notAStandardCategory')} >Andere</Button> 
    }

    return (
      <div className="categoryFilter">
        <Button type={typeFilter === 'contracts'? "primary" : "default"} onClick={()=>setTypeFilter('contracts')} >Verträge</Button>
        <Button type={typeFilter === 'bills'? "primary" : "default"} onClick={()=>setTypeFilter('bills')} >Rechnungen</Button>
        <Button type={typeFilter === 'inspection'? "primary" : "default"} onClick={()=>setTypeFilter('inspection')} >Prüfbescheinigungen</Button>
        <Button type={typeFilter === 'manuals'? "primary" : "default"} onClick={()=>setTypeFilter('manuals')} >Handbücher</Button>
        <Button type={typeFilter === 'logs'? "primary" : "default"} onClick={()=>setTypeFilter('logs')} >Logbücher</Button>
        <Button type={typeFilter === 'draws'? "primary" : "default"} onClick={()=>setTypeFilter('draws')} >Schaltpläne</Button>
        {buttonOthers}
        <Button type={typeFilter === 'photos'? "primary" : "default"} onClick={()=>setTypeFilter('photos')} >Fotos</Button>     
      </div>
      )
  }

  const getTableByFilter = () => {
    if(typeFilter === 'contracts'){
      return <TableContracts documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true}/>
    }
    else if(typeFilter === 'bills'){
      return <TableBills documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true}/>
    }
    else if(typeFilter === 'inspection'){
      return <TableInspectionCertificates documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true}/>
    }
    else if(typeFilter === 'manuals'){
      return <TableManuals documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true}/>
    }
    else if(typeFilter === 'logs'){
      return <TableLogs documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true}/>
    }
    else if(typeFilter === 'draws'){
      return <TableDrawings documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true}/>
    }
    else if(typeFilter === 'notAStandardCategory'){
      return <TableOthers documents={documents} contractsAreLoaded={state.contractsAreLoaded}/>
    }
    else if(typeFilter === 'photos'){
      return <TablePhotos documents={documents} contractsAreLoaded={state.contractsAreLoaded} isSingleInstalation={true} singleInstallation={props.installation}/>
    }
    return <>incorrect Filtertype</>
  }

  return (
    <div className=" bg-white pt-8 pb-8">
      <div className="pl-8">{getFilter()}</div>
      <br/>
      {getTableByFilter()}
    </div>
  );
}
export default Documents;

import { DocumentRef, } from "../../graphql/API";

export interface DocumentsType{
    contracts:DocumentRef[],
    bills:DocumentRef[],
    inspectionCertificats:DocumentRef[],
    manuals:DocumentRef[],
    logbooks:DocumentRef[],
    technicaDrawings:DocumentRef[],
    others:DocumentRef[],
    photos:DocumentRef[]
  }


  export const PhotoCategories = [
    { label: 'Tür', value: 'pc-door' },
    { label: 'Schaft', value: 'pc-shaft' },
    { label: 'Maschinenraum', value: 'pc-machine-room' },
    { label: 'Typenschild', value: 'pc-type-sign' },
    { label: 'Kabine', value: 'pc-cabin' },
    { label: 'Bedienelement', value: 'pc-control-element' },
  ]

  export const DocumentCategories = [
    { label: 'Logbuch', value: 'dc-photo' },
    { label: 'Schaltplan / tech. Zeichnung', value: 'dc-technical-drawing' },
    { label: 'Handbuch', value: 'dc-manual' },
    { label: 'Prüfbescheinigung', value: 'dc-inspection-certificate' },
  ]
  
  export const ContractCategories = [
    { label: 'Vollwartungsvertrag', value: 'cc-vollwartungsvertrag' },
    { label: 'Wartungsvertrag', value: 'cc-wartungsvertrag' },
    { label: 'Bereitschaftsvertrag / Personenbefreiung', value: 'cc-bereitschaftsvertrag' },
    { label: 'Notrufvertrag', value: 'cc-notrufvertrag' },
    { label: 'Vertrag ZÜS Leistung', value: 'cc-vertrag-zues-leistung' },
    { label: 'Abrufvertrag', value: 'cc-abrufvertrag' },
    { label: 'Rahmenwartungsvertrag', value: 'cc-rahmenwartungsvertrag' },
    { label: 'Angebot', value: 'cc-offered' },
    { label: 'Service-Angebot', value: 'cc-service-offered' },
    { label: 'Vertragsangebot', value: 'cc-contract-offered' },
    { label: 'Kündigung', value: 'cc-termination' },
    { label: 'Kündigungsbestätigung', value: 'cc-confirmation-of-termination' },
  ]

  export const BillCategories = [
    { label: 'Gutschrift', value: 'bc-crediting' },
    { label: 'Wartungsvertrag', value: 'bc-maintenance-contract' },
    { label: 'Notruf', value: 'bc-emergency-call' },
    { label: 'Hauptprüfung', value: 'bc-main-audit' },
    { label: 'Reparatur', value: 'bc-repair' },
    { label: 'Störung', value: 'bc-fault' },
    { label: 'Personenbefreiung', value: 'bc-personal-exemption' },  
    { label: 'Schlussrechnung', value: 'bc-final-invoice' },
    { label: 'Abschlagrechnung', value: 'bc-partial-invoice' },  
  ]
import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../../utils/globalLocalization';
import { strings as stringLocal } from './../DocumentsLocalization';
import { USER_CHOICE_LANGUAGE } from './../../utils/constants';
import { mergeDeepRight } from 'ramda';
import * as mutations from './../../graphql/mutations';
import { useEffect, useRef, useState, FocusEvent} from 'react';
import { message } from 'antd';
import { Installation, Query, SmartMaintenanceContractPage } from '../../graphql/API';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api'
import ContentEditable from 'react-contenteditable'
import { EditOutlined } from '@ant-design/icons';
import * as queries from './../../graphql/queries';
import { GlobalStateInterface, useGlobalState } from '../../utils/globlStateProvider';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

interface EditableTitleProps { installation: Installation }

const EditableTitle:  React.FC<EditableTitleProps> =  ({
        installation,
    }) => {
        const inputTitleRef = useRef<HTMLSpanElement>(null);
        const [assetTitle, setAssetTitle] = useState<string>('')
        const [assetTitleIsEditMode, setAssetTitleIsEditMode] = useState<boolean>(false)
        const { state, setState } = useGlobalState()

        useEffect(() => {
            if(assetTitleIsEditMode){
             inputTitleRef?.current?.focus();
            }
         }, [assetTitleIsEditMode]);        

         useEffect(() => {
                  if(installation.titleByCustomer && installation.titleByCustomer.length > 0){
                    setAssetTitle(installation.titleByCustomer)
                  }
                  else{
                    setAssetTitle(installation.serialNumber)
                  }
            
          }, [installation]);// eslint-disable-line react-hooks/exhaustive-deps

        const loadContracts = async() => {
            const res = await (API.graphql({ query: queries.contracts , variables:{ page:{}, filter:{} }}) as GraphQLResult<Query> );
            if(res.data?.contracts?.items != null){
              let dataGlobal:Partial<GlobalStateInterface> = {
                contracts: res.data?.contracts as SmartMaintenanceContractPage,
                contractsAreLoaded:true
              } 
              setState((prev) => ({ ...prev, ...dataGlobal }));
            }
          }

        const saveTitle = async (title:string) => {
            // TODO: implement saving routine
            const paramsSetTitle = {
            assetId: installation?._id,
            title: title
            };
            const newCoordinate:any = await API.graphql({ query: mutations.setAssetTitle, variables: {input: paramsSetTitle}});
            loadContracts() // change needs to ba global, reasons: reload / assetlist 
            if(newCoordinate.data?.setAssetTitle){
            message.success('Titel erfolgreich geändert!')
            }
            else{
            message.error('Es gab ein Problem beim speichern des neuen Titels!')
            }
        }

        const handleTitleBLur = (event:FocusEvent<HTMLInputElement>) => {
            if(event.target.innerText.length > 0){
            setAssetTitle(event.target.innerText)
            }
            else{
            if(installation?.serialNumber){
                setAssetTitle( installation?.serialNumber)
            }
            }    
        
            saveTitle(event.target.innerText)    
            setAssetTitleIsEditMode(false)
        };
        
      const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    
        // everytime allowed
        let whiteListKeys = ['ArrowRight','ArrowLeft','Backspace']
        if(whiteListKeys.includes(event.key)){
          return true
        }
    
        // no breaks
        if(event.key === 'Enter' ) {
          inputTitleRef?.current?.blur();
          event.preventDefault();
          return false;
        }
    
        // limit of chars
        var element = event.target as HTMLElement
        if(element.innerHTML.length > 30){
          event.preventDefault();
          return false;
        }
      }

  return (
    <>
        <ContentEditable
            html={assetTitle}
            disabled={!assetTitleIsEditMode}
            onChange={()=>{}}
            onBlur={handleTitleBLur}
            onKeyDown={handleKeydown}
            tagName='span'
            className="pr-4 pl-4"
            innerRef={inputTitleRef}
        />
        <EditOutlined className=" pb-1 m:pb-2 " style={{'color':'grey','fontSize':'22px','alignSelf':'flex-end'}} onClick={()=>setAssetTitleIsEditMode(true)} />
                
    </>
  );
}
export default EditableTitle;
